import React from 'react';

import ErrorPage from 'src/containers/Errors/ErrorPage';

export default () => (
  <ErrorPage
    errorNumber={403}
    text={`You either tried some shady route or you came here by mistake. Whichever
          it is, try using the navigation`}
    imageSrc="/images/error/undraw_wireframing_nxyi.svg"
    title="You have no access to this resource"
  />
);

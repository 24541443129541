import { LOGIN_PAGE } from 'src/constants';

const REDIRECT_FLAG_KEY = 'redirect';

export function hasRedirectFlag() {
  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.has(REDIRECT_FLAG_KEY);
}

export function getRedirectPath(pathname) {
  if ([LOGIN_PAGE, '/'].includes(pathname)) return '';

  return `?${REDIRECT_FLAG_KEY}`;
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Typography, makeStyles } from '@material-ui/core';

import MfaSetUpForm from 'src/containers/MfaPage/forms/MfaSetUpForm';
import * as IdentifierApi from 'src/api/interfaces/identifier';

const useStyles = makeStyles(({ spacing }) => ({
  mfaBlock: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: spacing(3, 3, 0),
    '& img': {
      width: '50%',
      maxWidth: spacing(50),
      minWidth: spacing(25),
    },
  },
  margin: {
    marginTop: spacing(1),
  },
}));

export default ({ handleSubmitSecureCode, username, session = null }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [secretCode, setSecretCode] = React.useState('');
  const [qrCode, setQrCode] = React.useState('');
  const [mfaCode, setMfaCode] = React.useState('');
  const [enableMfaSession, setEnableMfaSession] = React.useState('');

  React.useEffect(() => {
    const createAwsCode = async () => {
      const {
        secretCode,
        qrCode,
        session: enableMfaSession,
      } = await IdentifierApi.enableMfa(username, session);

      if (!!secretCode) setSecretCode(secretCode);
      if (!!qrCode) setQrCode(qrCode);
      if (!!enableMfaSession) setEnableMfaSession(enableMfaSession);
    };

    createAwsCode();
  }, []);

  const handleChange = ({ target }) => setMfaCode(target.value);
  const handleSubmit = event =>
    handleSubmitSecureCode(mfaCode, event, enableMfaSession);

  if (!secretCode || !qrCode) return null;

  return (
    <Card className={classes.mfaBlock}>
      <Typography component="h1" variant="h3">
        {t('Scan this QR code with your application.')}
      </Typography>
      <Typography component="h3" variant="h5" className={classes.margin}>
        {t(
          'Scan the QR code above with the two-factor authentication application on your phone.'
        )}
      </Typography>
      <img alt="" src={qrCode} />
      <MfaSetUpForm
        code={mfaCode}
        onSubmit={handleSubmit}
        onChange={handleChange}
      />
    </Card>
  );
};

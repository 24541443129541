import { API_URL_LEDGER } from 'src/config/environment';
import { Request, Endpoint } from 'src/lib/request';
import {
  pipe,
  errorRequestMixin,
  authRequestMixin,
  tenantRequestMixin,
  asyncRequestMixin,
  tokenRequestMixin,
  downloadRequestMixin,
} from 'src/api/mixin';

const STATUS_ENDPOINT = '/requests';

const LedgerRequest = pipe(Request)
  .chain(tenantRequestMixin)
  .chain(authRequestMixin)
  .chain(asyncRequestMixin)
  .chain(errorRequestMixin)
  .chain(tokenRequestMixin)
  .chain(downloadRequestMixin)
  .value();

const request = new LedgerRequest({
  host: API_URL_LEDGER,
  statusEndpoint: STATUS_ENDPOINT,
});
const endpoint = new Endpoint(request);

export default endpoint;

import { useHistory } from 'react-router-dom';

import { LOGIN_PAGE } from 'src/constants/pages';
import { getRedirectPath } from 'src/helpers/loginRedirect';
import { isPrivatePath } from 'src/routes';

export default () => {
  const history = useHistory();

  return () => {
    if (isPrivatePath(history.location.pathname)) return;

    localStorage.clear();
    history.push(`${LOGIN_PAGE}${getRedirectPath(history.location.pathname)}`);
  };
};

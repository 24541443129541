import { Initializer } from 'src/lib/initializeManager';
import { getSchemaFeatures } from 'src/api/interfaces/featuresSite';

class SchemaFeatureService extends Initializer {
  #features = null;

  async initialize() {
    this.#features = await getSchemaFeatures();
  }

  getAutocompleteRules(schemaType, schemaName) {
    return this.#features[schemaType]?.[schemaName]?.autocomplete ?? [];
  }

  dispose() {
    this.#features = null;
  }
}

export default new SchemaFeatureService();

import { ledger, asyncLedger } from 'src/api/endpoint';

/** POLICIES */

export function getPoliciesListUrl() {
  return '/policies';
}

export function getPolicies(limit) {
  const policiesListUrl = getPoliciesListUrl();

  return ledger.get(
    `${policiesListUrl}?useCursorPagination=true&page[limit]=${limit}`
  );
}

export function getRawPolicy(id) {
  return ledger.get(`/policies/${id}`);
}

export function getPolicyViewData(id, forceCoverageDateFormat) {
  return ledger.get(
    `/policies/${id}/links?forceCoverageDateFormat=${forceCoverageDateFormat}`
  );
}

export function getPolicyHistoryViewData(id, version, forceCoverageDateFormat) {
  return ledger.get(`/policies/${id}/links`, {
    version,
    forceCoverageDateFormat: `${forceCoverageDateFormat}`,
  });
}

export function getPolicyActions(id) {
  return ledger.get(`/policies/${id}/actions`);
}

export function sendConfirmationEmail(id) {
  return ledger.post(`/policies/${id}/confirmation-email`);
}

export function getRawPolicyMta(id) {
  return ledger.get(`/policies/mta/${id}`);
}

export function postPolicyMta(data) {
  return ledger.post(`/sales/policies/mta`, data);
}

export function getPolicyChangeData(id) {
  return ledger.get(`/policies/${id}/data-change`);
}

export function updatePolicyChangeData(id, data) {
  return ledger.put(`/policies/${id}/data-change`, data);
}

export function getRenewalPolicy(id) {
  return ledger.get(`/policies/${id}/renewal`);
}

export function getItemInstallments(id) {
  return ledger.get(`/policies/${id}/schedule`);
}

export function getItemInvoices(id) {
  return ledger.get(`/policies/${id}/invoices`);
}

export function getHistory(id) {
  return ledger.get(`/policies/${id}/history`);
}

export function terminatePolicy(id, data) {
  return ledger.post(`/policies/${id}/terminate`, data);
}

export function revertTerminationPolicy(id) {
  return ledger.post(`/policies/${id}/revert-termination`);
}

export function getTerminationData(id, policy) {
  return ledger.post(`/policies/${id}/terminate/premiums`, {
    policy,
  });
}

export function getClaimData(id) {
  return ledger.get(`/policies/${id}/claim-data`);
}

export function getPolicyChanges(id) {
  return ledger.get(`/sales/policies/${id}/changes`);
}

export function getPolicyVersionsURL(id) {
  return `/policies/${id}/versions`;
}

/** DRAFTS */

export function editDraft(id, data) {
  return ledger.put(`/sales/policies/${id}`, data);
}

export function calculateDraft(id, data) {
  return ledger.post(
    `/sales/policies/${id}/calculate?withNotifications=true`,
    data
  );
}

export function getPolicyViewPeriods(id) {
  return ledger.get(`/policies/${id}/period-totals`);
}

export function getPolicyHistoryViewPeriods(id, version) {
  return ledger.get(`/policies/${id}/period-totals`, { version });
}

export function getPolicyViewInstalments(id) {
  return ledger.get(`/policies/${id}/custom-view/installments`);
}

export function getPolicyEventsUrl(id) {
  return `/policies/${id}/history/events`;
}

export function getQuoteEventsUrl(id) {
  return `/sales/policies/${id}/events`;
}

export function downloadEventPayload(policyId, eventId) {
  return ledger.download.get(`/sales/policies/${policyId}/events/${eventId}`);
}

export function issueQuote(id, data) {
  return asyncLedger.post(`/sales/policies/${id}/issue`, data);
}

export function getQuotesListUrl() {
  return '/sales/paginated-policies';
}

export function getQuotesByIdUrl(customerId) {
  return `/lists/related-quotes/${customerId}`;
}

export function getPoliciesByIdUrl(customerId) {
  return `/lists/related-policies/${customerId}`;
}

export function getQuotes(limit) {
  const quotesListUrl = getQuotesListUrl();

  return ledger.get(
    `${quotesListUrl}?useCursorPagination=true&page[limit]=${limit}`
  );
}

export function createQuote(policy) {
  return ledger.post('/sales/policies/create', policy);
}

export function copyQuote(data) {
  return ledger.post('/sales/policies/copy', data);
}

export function bindQuote(id, data) {
  return ledger.post(`/sales/policies/${id}/bind`, data);
}

export function unbindQuote(id, data) {
  return ledger.post(`/sales/policies/${id}/unbind`, data);
}

export function declineQuote(id, data) {
  return ledger.post(`/sales/policies/${id}/decline`, data);
}

export function requestQuoteInfo(id, data) {
  return ledger.post(
    `/sales/policies/${id}/request-information-by-email`,
    data
  );
}

export function getPeriodPremiumsById(id) {
  return ledger.get(`/sales/policies/${id}/calculation`);
}

export const makePeriodPremiumsByData = () => {
  const singletonRequest = ledger.singleton('POST');

  return data => singletonRequest('/sales/policies/calculation', data);
};

export function sendReferral(id, data) {
  return ledger.post(`/sales/policies/${id}/referrals`, data);
}

export function acceptReferral(id, data) {
  return ledger.post(`/sales/policies/${id}/referrals/accept`, data);
}

export function declineReferral(id, data) {
  return ledger.post(`/sales/policies/${id}/referrals/decline`, data);
}

/** SCHEMAS */

export function getSimpleSchema({ type, name, version }) {
  return ledger.get(`/schemes/${type}/simple-quote/${name}/${version}/scheme`);
}

export function getRegularSchemaProducts({ type, name, version }) {
  const cacheableRequest = ledger.cacheable('GET');

  return cacheableRequest(
    `/schemes/${type}/regular/${name}/${version}/products`
  );
}

export function getRegularSchema({ type, name, version }) {
  return ledger.get(`/schemes/${type}/regular/${name}/${version}/scheme`);
}

export function getQuoteIssueSchema({ type, name, version }) {
  return ledger.get(
    `/schemes/${type}/quote-issue-popup/${name}/${version}/scheme`
  );
}

export function getTerminationSchema(id) {
  return ledger.get(`/schemes/policy/${id}/termination/scheme`);
}

export function getSchemaByPath(schemaPath) {
  return ledger.get(`/schemes/${schemaPath}/scheme`);
}

/* UI SCHEMAS */

export function getSimpleUISchema({ type, name, version }) {
  return ledger.get(`/schemes/${type}/simple-quote/${name}/${version}/ui`);
}

export function getRegularUISchema({ type, name, version }) {
  return ledger.get(`/schemes/${type}/regular/${name}/${version}/ui`);
}

export function getQuoteIssueUISchema({ type, name, version }) {
  return ledger.get(`/schemes/${type}/quote-issue-popup/${name}/${version}/ui`);
}

export function getTerminationUISchema(id) {
  return ledger.get(`/schemes/policy/${id}/termination/ui`);
}

export function getUISchemaByPath(schemaPath) {
  return ledger.get(`/schemes/${schemaPath}/ui`);
}

/* PACKAGES */

export function getPackagesById(id) {
  return ledger.get(`/sales/policies/${id}/packages/config`);
}

export function getPackagesByName(name) {
  return ledger.get(`/sales/product-features/${name}/packages`);
}

export function getAggregatedCalculation(id) {
  return ledger.get(`/sales/policies/${id}/packages/aggregated-calculation`);
}

export function setPackage(id, packageData) {
  return ledger.post(`/sales/policies/${id}/packages/set`, packageData);
}

/** COMMISSIONS **/

export function setCommissions(schemaName, policy) {
  return ledger.post(
    `/policies/set-commissions?schemaName=${schemaName}`,
    policy
  );
}

export function setDraftCommissions(schemaName, policy) {
  return ledger.post(
    `/sales/policies/set-commissions?schemaName=${schemaName}`,
    policy
  );
}

export function getProductSchemas() {
  return ledger.get(`/policies/products`);
}

/** BUSINESS RULE PROCESSOR **/

export function getPolicyFormData(id) {
  return ledger.get(`/sales/policies/${id}?withNotifications=true`);
}

export function getPolicyActionsById(id, waitingActionsDelay = 1000) {
  return new Promise((resolve, reject) => {
    const waitForActions = () =>
      ledger
        .doRaw('GET', `/sales/policies/actions/${id}?longPolling=true`)
        .then(({ status, data }) => {
          switch (status) {
            case 200: {
              return resolve(data);
            }
            case 204: {
              return setTimeout(waitForActions, waitingActionsDelay);
            }
            default: {
              throw new Error(`Unknown status: ${status}`);
            }
          }
        })
        .catch(() => {
          reject(
            new Error(
              `Operation processing took too long or error happened. Please contact with support.`
            )
          );
        });

    waitForActions();
  });
}

export function getPolicyActionsByData(formData) {
  return ledger.post('/sales/policies/actions', formData);
}

export function mailQuote(id) {
  return ledger.post(`/sales/policies/${id}/send-email`);
}

export function debugCalculate(id) {
  return ledger.download.post(`/sales/policies/${id}/calculate-debug`);
}

export function importData(id, data) {
  return ledger.post(`/sales/policies/${id}/import-data`, data);
}

export function importQuote(schemaName, data) {
  return ledger.post(`/sales/quotes/${schemaName}/from-file`, data);
}

export function updateLhvCrmCustomerData(quoteId) {
  return ledger.get(`/lhv/crm/update-customer-data/${quoteId}`);
}

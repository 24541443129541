import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, makeStyles } from '@material-ui/core';

const UNKNOWN_MESSAGES = ['Something went wrong'];

const useStyles = makeStyles(({ spacing, color }) => ({
  authErrorMessages: {
    padding: spacing(2),
    margin: spacing(2, 0),
    backgroundColor: color.error.main,
  },
  errorMessage: {
    fontSize: 16,
    lineHeight: '24px',
  },
}));

const ErrorMessage = ({ message }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.errorMessage}>
      <strong>{t('Error')}</strong>: {t(message)}
    </div>
  );
};

const getErrorMessages = error => {
  if (!Array.isArray(error?.errors)) return UNKNOWN_MESSAGES;

  const messages = error.errors
    .map(error => error?.message)
    .filter(Boolean)
    .map(error => {
      const [message] = error.split(':').slice(-1);

      return message ?? `${error}`;
    });

  return messages.length ? messages : UNKNOWN_MESSAGES;
};

export default ({ error, className }) => {
  const classes = useStyles();

  if (!error) return null;

  const messages = getErrorMessages(error);

  return (
    <Paper className={clsx(classes.authErrorMessages, className)}>
      {messages.map((message, index) => (
        <ErrorMessage
          key={`auth-error-${message}-${index}`}
          message={message}
        />
      ))}
    </Paper>
  );
};

import * as IdentifierApi from 'src/api/interfaces/identifier';

export const ChallengeName = {
  SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  MFA_SETUP: 'MFA_SETUP',
};

export const signInRequest = credentials => IdentifierApi.login(credentials);

export const firstLoginRequest = (credentials, loginData, passwordData) => {
  const requestData = {
    challengeName: loginData.challengeName,
    challengeResponses: {
      username: credentials.username,
      newPassword: passwordData.newPassword,
      userAttributes: {
        name: passwordData.fullName,
      },
    },
    session: loginData.data.session,
  };

  return IdentifierApi.challenge(requestData);
};

export const mfaRequest = (credentials, loginData, secureCode) => {
  const requestData = {
    challengeName: ChallengeName.SOFTWARE_TOKEN_MFA,
    challengeResponses: {
      username: credentials.username,
      softwareTokenMfaCode: secureCode,
    },
    session: loginData.data.session,
  };

  return IdentifierApi.challenge(requestData);
};

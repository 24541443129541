import lodash from 'lodash';

import defaultContent from 'src/theme/defaultContent.json';
import {
  getFeatures,
  getBrokerThemeConfig,
} from 'src/api/interfaces/featuresSite';
import { Initializer } from 'src/lib/initializeManager';
import { normalizeTheme } from 'src/theme';
import tenantTagService from 'src/service/TenantTagService';

const BROWSER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

const getLocaleFormat = locale => {
  const localeLists = require('dayjs/locale.json');

  const keyWithRegion = lodash.toLower(`${locale.language}-${locale.region}`);
  const keyLanguage = locale.language;
  const keyDefault = 'en';

  return lodash
    .chain(localeLists)
    .keyBy('key')
    .at([keyWithRegion, keyLanguage, keyDefault])
    .value()
    .find(el => !!el);
};

const getIntl = name => {
  const locale = new Intl.Locale(name ?? 'en-gb');
  const number = new Intl.NumberFormat(locale.baseName, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 1,
    minimumIntegerDigits: 1,
    notation: 'standard',
    numberingSystem: 'latn',
    signDisplay: 'never',
    style: 'decimal',
    useGrouping: 'always',
  });
  const numberPairs = Object.fromEntries(
    number.formatToParts(1_000_000).map(({ type, value }) => [type, value])
  );

  return {
    locale: locale.baseName,
    language: locale.language,
    localeFormat: getLocaleFormat(locale),
    numberGroup: numberPairs.group,
    numberDecimal: numberPairs.decimal,
  };
};

class PublicFeatureService extends Initializer {
  #theme = null;
  #content = null;
  #intl = null;
  #languages = null;
  #timezone = null;
  #claimManagement = null;
  #invoiceImportCsvSelection = null;

  async initialize() {
    const { brokerTag } = tenantTagService;

    let brokerTheme;

    const {
      theme: commonTheme,
      content,
      locale,
      languages,
      timezone,
      claim_management: claimManagement,
      invoice_import_csv_selection: invoiceImportCsvSelection,
    } = await getFeatures();

    if (brokerTag) brokerTheme = await getBrokerThemeConfig();

    this.#intl = getIntl(locale);

    this.#content = content ?? defaultContent;

    this.#languages = languages;

    this.#timezone = timezone || BROWSER_TIMEZONE;

    this.#theme = brokerTag
      ? normalizeTheme(brokerTheme)
      : normalizeTheme(commonTheme);

    this.#claimManagement = claimManagement;

    this.#invoiceImportCsvSelection = invoiceImportCsvSelection;
  }

  get theme() {
    return this.#theme;
  }

  get content() {
    return this.#content;
  }

  get intl() {
    return this.#intl;
  }

  get localeKey() {
    return this.#intl.localeFormat.key;
  }

  get languages() {
    return this.#languages;
  }

  get timezone() {
    return this.#timezone;
  }

  get claimManagement() {
    return this.#claimManagement;
  }

  get invoiceImportCsvSelection() {
    return this.#invoiceImportCsvSelection;
  }

  dispose() {
    this.#theme = null;
    this.#content = null;
    this.#intl = null;
    this.#languages = null;
    this.#timezone = null;
    this.#invoiceImportCsvSelection = null;
  }
}

export default new PublicFeatureService();

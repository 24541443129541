import tenantTagService from 'src/service/TenantTagService';
import { convertKeysToCamelCase } from 'src/helpers/objects';

import * as SitesApi from '../sites';

export async function getAwsCredentials() {
  const { tenantTag } = tenantTagService;

  try {
    const response = await SitesApi.getAwsCredentials(tenantTag);

    return convertKeysToCamelCase(response);
  } catch (error) {
    throw error.response.data;
  }
}

export async function getTenantTag() {
  const { hostname } = window.location;

  try {
    const { data } = await SitesApi.getTenantTag(hostname);

    return convertKeysToCamelCase(data);
  } catch (error) {
    throw error.response.data;
  }
}

import getHash from 'src/helpers/getHash';
import awsService from 'src/service/AwsService';
import * as IdentifierApi from 'src/api/interfaces/identifier';
import decodeJWT from 'src/helpers/decodeJWT';

const initialState = {
  hash: null,
  prevHash: null,
  loggedIn: false,
  resources: [],
};

/** selectors */
export const selectUserState = state => state.auth;
export const selectIsLoggedIn = state =>
  state.auth.loggedIn && !!awsService.isSessionActive;
export const selectUserResources = state => state.resources;

/** action types */
const prefix = 'auth';

export const ActionTypes = {
  LOGIN: `${prefix}/LOGIN`,
  LOGOUT: `${prefix}/LOGOUT`,
};

/** actions */

export function login(tokens) {
  return async dispatch => {
    awsService.setTokens(tokens);

    try {
      const user = decodeJWT(tokens.idToken);

      const hash = await getHash(user.email);

      dispatch({
        type: ActionTypes.LOGIN,
        payload: {
          ...user,
          usernname: user.email,
          hash,
        },
      });
    } catch (error) {
      awsService.dispose();
      console.error(error);
    }
  };
}

export function logout() {
  return dispatch => {
    IdentifierApi.validateToken().then(isValid => {
      if (isValid) IdentifierApi.logout();

      awsService.dispose();
    });

    return dispatch({
      type: ActionTypes.LOGOUT,
    });
  };
}

/** default reducer */
export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.LOGIN:
      return {
        ...payload,
        hash: payload.hash,
        prevHash: state.hash,
        loggedIn: true,
      };
    case ActionTypes.LOGOUT:
      return {
        ...initialState,
        hash: state.hash,
        prevHash: state.prevHash,
      };
    default:
      return state;
  }
}

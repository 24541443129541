import { init } from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { SENTRY_DSN, SENTRY_ENV, SENTRY_RATE } from 'src/config/environment';

function beforeSend(event, hint) {
  const error = hint.originalException;

  if (error.response)
    event.extra = {
      ...event.extra,
      responseHeaders: error.response.headers,
    };

  return event;
}

if (!!SENTRY_DSN)
  init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    tracesSampleRate: SENTRY_RATE,
    integrations: [new Integrations.BrowserTracing()],
    beforeSend: beforeSend,
  });

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography, makeStyles } from '@material-ui/core';

import useAsyncDispatch from 'src/hooks/useAsyncDispatch';
import { Form, AuthError } from 'src/components/AuthForm';
import { login } from 'src/redux/modules/auth';

import { mfaRequest } from '../requestHelper';

import Digits from './Digits';

const NUMBER_OF_TEXT_FIELDS = 6;

const useStyles = makeStyles(({ typography, spacing }) => ({
  formBlock: {
    marginLeft: spacing(6),
    marginTop: spacing(15),
    paddingRight: spacing(4),
  },
  formTitle: {
    fontWeight: typography.fontWeightBold,
  },
  backButton: {
    height: spacing(6),
    marginTop: spacing(3),
    textTransform: 'capitalize',
  },
  margin: {
    marginTop: spacing(3),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: spacing(3),
  },
  submitButton: {
    flex: 1,
    height: spacing(6),
    marginTop: spacing(3),
    marginLeft: spacing(2),
    textTransform: 'capitalize',
  },
}));

export default ({ value, action }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [error, setError] = React.useState(null);
  const [isLoading, asyncDispatch] = useAsyncDispatch();

  const [digits, updateDigits] = React.useState(() =>
    Array.from({ length: NUMBER_OF_TEXT_FIELDS }, () => '')
  );

  const handleSubmit = event => {
    event.preventDefault();
    setError(null);

    asyncDispatch(async dispatch => {
      try {
        const secureCode = digits.join('');
        const { authenticationResult } = await mfaRequest(
          value.credentials,
          value.loginData,
          secureCode
        );

        if (!!authenticationResult)
          return login(authenticationResult)(dispatch);
      } catch (error) {
        setError(error);
        console.error(error);
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Typography component="h1" variant="h3" className={classes.formTitle}>
        {t('2 factor authentication')}
      </Typography>
      <Typography component="h3" variant="h5" className={classes.margin}>
        {t('Enter the six-digit code from the application to continue.')}
      </Typography>
      <AuthError error={error} />
      <Digits
        digits={digits}
        updateDigits={updateDigits}
        disabled={isLoading}
      />
      <div className={classes.buttons}>
        <Button
          disabled={isLoading}
          className={classes.backButton}
          variant="outlined"
          color="primary"
          onClick={action.goToSignIn}
        >
          {t('Back')}
        </Button>
        <Button
          disabled={isLoading}
          className={classes.submitButton}
          variant="contained"
          color="primary"
          type="submit"
        >
          {t('submit')}
        </Button>
      </div>
    </Form>
  );
};

import lodash from 'lodash';

class AxiosCache {
  cache = new Map();
  pendingRequests = new Map();

  init(data) {
    return this.generateCacheKey(data);
  }

  async cacheableRequest(cacheKey, axiosRequest, cacheDuration = 6000) {
    const currentTime = Date.now();

    if (this.pendingRequests.has(cacheKey))
      return this.pendingRequests.get(cacheKey);

    if (this.cache.has(cacheKey)) {
      const cachedResponse = this.cache.get(cacheKey);

      if (currentTime - cachedResponse.timestamp < cacheDuration)
        return Promise.resolve(cachedResponse.response);
      else this.cache.delete(cacheKey);
    }

    return this.request(cacheKey, axiosRequest, { currentTime });
  }

  async promisableRequest(cacheKey, axiosRequest) {
    if (this.pendingRequests.has(cacheKey))
      return this.pendingRequests.get(cacheKey);

    return this.request(cacheKey, axiosRequest, { cacheEnabled: false });
  }

  async request(cacheKey, axiosRequest, options = {}) {
    const { currentTime = Date.now(), cacheEnabled = true } = options;

    const requestPromise = axiosRequest()
      .then(response => {
        cacheEnabled &&
          this.cache.set(cacheKey, {
            response,
            timestamp: currentTime,
          });
        this.pendingRequests.delete(cacheKey);

        return response;
      })
      .catch(error => {
        this.pendingRequests.delete(cacheKey);
        throw error;
      });

    this.pendingRequests.set(cacheKey, requestPromise);

    return requestPromise;
  }

  generateCacheKey({ url, params }) {
    if (!lodash.isEmpty(params) && lodash.isObject(params)) {
      const paramsString = new URLSearchParams(this.sortParamKeys(params));

      return url + String(paramsString);
    }

    return url;
  }

  sortParamKeys = params =>
    lodash(params)
      .toPairs()
      .sortBy(0)
      .fromPairs()
      .value();
}

export default new AxiosCache();

import React, { Suspense, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LinearProgress, Hidden, makeStyles } from '@material-ui/core';

import useLayoutStyles from '../useLayoutStyles';
import PoweredByInsly from '../icons/powered_by_insly.svg';

import LeftBar from './LeftBar';
import TopBar from './TopBar';

const useStyles = makeStyles(({ spacing }) => ({
  logoContainer: {
    paddingTop: spacing(2),
    marginTop: 'auto',
    marginBottom: spacing(4),
    marginLeft: spacing(2),
  },
  logo: {
    width: 192,
    height: 29,
  },
}));

export default ({ children }) => {
  const location = useLocation();
  const classes = useLayoutStyles(location);
  const dashboardClasses = useStyles();

  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [openNavBarDesktop, setOpenNavBarDesktop] = useState(true);

  const handleDesktopToggle = (_, open) =>
    setOpenNavBarDesktop(
      typeof open !== 'undefined' ? open : !openNavBarDesktop
    );

  return (
    <div className={classes.layout}>
      <Hidden mdUp>
        <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
      </Hidden>
      <LeftBar
        onMobileClose={() => setOpenNavBarMobile(false)}
        onDesktopToggle={handleDesktopToggle}
        openMobile={openNavBarMobile}
        openDesktop={openNavBarDesktop}
      />
      <div className={classes.layoutContainer}>
        <Suspense fallback={<LinearProgress />}>
          {children}
          <div className={dashboardClasses.logoContainer}>
            <img
              src={PoweredByInsly}
              className={dashboardClasses.logo}
              alt="Powered by Insly"
            />
          </div>
        </Suspense>
      </div>
    </div>
  );
};

import React from 'react';
import { ErrorBoundary } from '@sentry/react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { useTranslation } from 'react-i18next';
import CssBaseline from '@material-ui/core/CssBaseline';
import dayjs from 'dayjs';

import NotificationProvider from 'src/providers/NotificationProvider';
import ThemeProvider from 'src/providers/ThemeProvider';
import LoginPage from 'src/containers/LoginPage';
import ForgotPassword from 'src/containers/ForgotPassword/ForgotPassword';
import Loading from 'src/components/Loading';
import ApiErrorHandler from 'src/components/ApiErrorHandler';
import { LOGIN_PAGE, FORGOT_PASSWORD_PAGE } from 'src/constants';
import routes from 'src/routes';
import UserTracker from 'src/components/UserTracker';
import UserRedirect from 'src/components/UserRedirect';
import initializationService from 'src/service/InitializationService';
import publicFeatureService from 'src/service/PublicFeatureService';

import PickerProvider from './providers/PickerProvider';

export default () => {
  const history = useHistory();
  const { ready } = useTranslation('translation', { useSuspense: false });
  const [isInitialized, setInitializationState] = React.useState(null);

  React.useEffect(() => {
    initializationService.public
      .initialize()
      .then(() => {
        if (publicFeatureService.timezone && dayjs.tz)
          dayjs.tz.setDefault(publicFeatureService.timezone);
      })
      .catch(error => {
        console.error(error);
        history.push('/errors/error-404');
      })
      .finally(() => setInitializationState(true));
  }, [history]);

  if (!ready || !isInitialized) return <Loading />;

  return (
    <PickerProvider>
      <ThemeProvider>
        <NotificationProvider>
          <UserRedirect />
          <UserTracker />
          <CssBaseline />
          <Switch>
            <Route path="/" exact>
              <Redirect to={LOGIN_PAGE} />
            </Route>
            <Route path={LOGIN_PAGE} exact>
              <LoginPage />
            </Route>
            <Route path={FORGOT_PASSWORD_PAGE} exact>
              <ForgotPassword />
            </Route>
            <ErrorBoundary fallback={<Loading />}>
              {renderRoutes(routes)}
            </ErrorBoundary>
          </Switch>
          <ApiErrorHandler />
        </NotificationProvider>
      </ThemeProvider>
    </PickerProvider>
  );
};

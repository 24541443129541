import awsService from 'src/service/AwsService';

export default Target =>
  class extends Target {
    getConfig(method, url, data, config) {
      const { accessToken } = awsService;
      const requestConfig = super.getConfig(method, url, data, config);
      const headers = {
        ...requestConfig.headers,
        Authorization: `Bearer ${accessToken}`,
      };

      return {
        ...requestConfig,
        headers,
      };
    }
  };

import { ledger } from 'src/api/endpoint';

export function fetch(page, perPage, searchterm) {
  return ledger.get('/sales/make-model', { page, perPage, searchterm });
}

export function getItemData(id) {
  return ledger.get(`/sales/make-model/${id}`);
}

export function edit(id, data) {
  return ledger.put(`/sales/make-model/${id}`, data);
}

export function insert(data) {
  return ledger.post('/sales/make-model', data);
}

export function fileUpload(data) {
  return ledger.post('/sales/make-model/file-upload', data);
}

/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { IS_DEV_MODE, GA_MEASUREMENT_ID } from 'src/config/environment';

function Page({ title, children, pageRef, ...rest }) {
  const location = useLocation();

  useEffect(() => {
    if (IS_DEV_MODE) return;

    window.gtag?.('config', GA_MEASUREMENT_ID, {
      page_path: location.pathname,
      page_name: title,
    });
  }, []);

  return (
    <HelmetProvider>
      <div {...rest} ref={pageRef}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {children}
      </div>
    </HelmetProvider>
  );
}

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Page;

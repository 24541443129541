import awsService from 'src/service/AwsService';
import StatusPromise from 'src/lib/statusPromise';
import { refreshToken } from 'src/api/interfaces/identifier';

const ISOLATED_REQUEST_CONFIG = { isIsolated: true };

const tokens = {
  promise: StatusPromise.resolve(),
  createRequest() {
    return new StatusPromise((resolve, reject) =>
      refreshToken(ISOLATED_REQUEST_CONFIG)
        .then(({ authenticationResult }) => resolve(authenticationResult))
        .catch(error => reject(error))
    );
  },
  makeRequest() {
    if (!this.promise.isPending) this.promise = this.createRequest();

    return this.promise;
  },
};

export default Target =>
  class extends Target {
    interceptors() {
      this._commonTransport.interceptors.response.use(null, error =>
        this._tokenInterceptor(error)
      );

      super.interceptors();
    }

    async _tokenInterceptor(error) {
      if (error.response?.status !== 401) throw error;

      try {
        const newTokens = await tokens.makeRequest();

        awsService.setTokens(newTokens);
      } catch {
        awsService.dispose();
        window.location.href = window.location.origin;

        return;
      }

      const { method, url, body, ...config } = error.config;

      return this.doRaw(method, url, body, {
        ...config,
        ...ISOLATED_REQUEST_CONFIG,
      });
    }
  };

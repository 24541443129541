import * as LedgerFeaturesApi from 'src/api/featuresLedger';

/* Product feature */

export async function getProductFeatures(schemaName) {
  try {
    const response = await LedgerFeaturesApi.getProductFeatures(schemaName);

    return response;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getProductFeature(schemaName, featureName) {
  try {
    const response = await LedgerFeaturesApi.getProductFeature(
      schemaName,
      featureName
    );

    return response;
  } catch (error) {
    throw error.response.data;
  }
}

export async function updateProductFeature(schemaName, payload) {
  try {
    return await LedgerFeaturesApi.updateProductFeature(schemaName, payload);
  } catch (error) {
    throw error.response.data;
  }
}

export async function removeProductFeature(schemaName, featureName) {
  try {
    return await LedgerFeaturesApi.updateProductFeature(schemaName, {
      [featureName]: null,
    });
  } catch (error) {
    throw error.response.data;
  }
}

/* Tenant feature */

export async function getTenantFeatures() {
  try {
    return await LedgerFeaturesApi.getTenantFeatures();
  } catch (error) {
    throw error.response.data;
  }
}

export async function getTenantFeature(featureName) {
  try {
    const response = await LedgerFeaturesApi.getTenantFeature(featureName);

    return response;
  } catch (error) {
    throw error.response.data;
  }
}

export async function updateTenantFeature(payload) {
  try {
    return await LedgerFeaturesApi.updateTenantFeature(payload);
  } catch (error) {
    throw error.response.data;
  }
}

export async function removeTenantFeature(featureName) {
  try {
    return await LedgerFeaturesApi.updateTenantFeature({
      [featureName]: null,
    });
  } catch (error) {
    throw error.response.data;
  }
}

export async function getChatSettings() {
  try {
    return await LedgerFeaturesApi.getChatSettings();
  } catch (error) {
    throw error.response.data;
  }
}

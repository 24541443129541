import MuiPaper, { ROUNDED_BORDER_RADIUS } from './MuiPaper';

const SPACE_BETWEEN = 32;

export default {
  root: {
    ...MuiPaper.root,
    margin: '0 !important',
    '& + &': {
      margin: `${SPACE_BETWEEN}px 0 0 !important`,
    },
  },
  rounded: {
    ...MuiPaper.rounded,
    '&:first-child': {
      borderTopLeftRadius: ROUNDED_BORDER_RADIUS,
      borderTopRightRadius: ROUNDED_BORDER_RADIUS,
    },
    '&:last-child': {
      borderBottomLeftRadius: ROUNDED_BORDER_RADIUS,
      borderBottomRightRadius: ROUNDED_BORDER_RADIUS,
    },
  },
};

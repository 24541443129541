import lodash from 'lodash';
import { Redirect, matchPath } from 'react-router-dom';

import Private from 'src/layouts/Private';
import Simple from 'src/layouts/Simple';

import privateRoutes from './privateRoutes';
import publicRoutes from './publicRoutes';
import errorsRoutes from './errorsRoutes';

const getPaths = routes =>
  lodash
    .map(routes, 'path')
    .flat()
    .filter(Boolean);

const PRIVATE_PATHS = getPaths(privateRoutes);
const PUBLIC_PATHS = getPaths(publicRoutes);
const ERROR_PATHS = getPaths(errorsRoutes);

export const isPrivatePath = pathname =>
  !!matchPath(pathname, {
    path: PRIVATE_PATHS,
    exact: true,
    strict: true,
  });

export const isPublicPath = pathname =>
  !!matchPath(pathname, {
    path: PUBLIC_PATHS,
    exact: true,
    strict: true,
  });

export const isErrorPath = pathname =>
  !!matchPath(pathname, {
    path: ERROR_PATHS,
    exact: true,
    strict: true,
  });

const getLayout = pathname => {
  switch (true) {
    case isPrivatePath(pathname):
      return Private;
    default:
      return Simple;
  }
};

const Layout = ({ route, location }) => {
  const { pathname } = location;
  const Layout = getLayout(pathname);

  return <Layout route={route} />;
};

export default [
  {
    component: Layout,
    routes: [
      ...privateRoutes,
      ...publicRoutes,
      ...errorsRoutes,
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
];

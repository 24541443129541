import { sites, sitesPublic, ledger, ums } from 'src/api/endpoint';

export function getSchemasList(tenantTag) {
  return sitesPublic.get(`/schemes/configurations/${tenantTag}`);
}

export function getSchemasListByType(tenantTag, type) {
  return sitesPublic.get(`/schemes/configurations/${tenantTag}/${type}`);
}

export function getLatestSchema(tenantTag, type, name) {
  return sitesPublic.get(
    `/schemes/configurations/${tenantTag}/${type}/${name}`,
    null,
    { preventErrorHandler: true }
  );
}

export function getLatestUserSchema(tenantTag) {
  return ums.get(`/${tenantTag}/schema/user/schema`);
}

export function getLatestSchemaWithInfo(tenantTag, type, name) {
  return sitesPublic.get(
    `/schemes/configurations/${tenantTag}/${type}/${name}?withSchemaBlock=true`
  );
}

export function getVersionedSchema(tenantTag, type, name, version) {
  return sitesPublic.get(
    `/schemes/configurations/${tenantTag}/${type}/${name}/${version}`
  );
}

export function getVersionedSchemaWithInfo(tenantTag, type, name, version) {
  return sitesPublic.get(
    `/schemes/configurations/${tenantTag}/${type}/${name}/${version}?withSchemaBlock=true`
  );
}

export function getVersionedUISchema(tenantTag, type, name, version) {
  return sitesPublic.get(
    `/schemes/configurations/${tenantTag}/${type}/${name}/${version}/ui`
  );
}

export function getVersionedUISchemaWithInfo(tenantTag, type, name, version) {
  return sitesPublic.get(
    `/schemes/configurations/${tenantTag}/${type}/${name}/${version}/ui?withSchemaBlock=true`
  );
}

export function getLatestUISchema(tenantTag, type, name) {
  return sitesPublic.get(
    `/schemes/configurations/${tenantTag}/${type}/${name}/ui`,
    null,
    { preventErrorHandler: true }
  );
}

export function getLatestUserUISchema(tenantTag) {
  return ums.get(`/${tenantTag}/schema/user/ui`);
}

export function getLatestUISchemaWithInfo(tenantTag, type, name) {
  return sitesPublic.get(
    `/schemes/configurations/${tenantTag}/${type}/${name}/ui?withSchemaBlock=true`
  );
}

export function editSchema(tenantTag, type, name, schema) {
  return sites.post(
    `/schemes/configurations/${tenantTag}/${type}/${name}`,
    schema
  );
}

export function editUISchema({ tenantTag, type, name, version, uiSchema }) {
  return sites.put(
    `/schemes/configurations/${tenantTag}/${type}/${name}/${version}/ui`,
    uiSchema
  );
}

export function getSchemasByType(tenantTag, type) {
  return sitesPublic.get(`/schemes/configurations/${tenantTag}/${type}`);
}

export function getFeatureUiSchema(featureName) {
  return ledger.get(`/schemes/feature/${featureName}/ui`);
}

export function getFeatureSchema(featureName) {
  return ledger.get(`/schemes/feature/${featureName}/scheme`);
}

import React from 'react';
import { Button, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Page from 'src/components/Page';
import { selectIsLoggedIn } from 'src/redux/modules/auth';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: '10vh',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  imageContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto',
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const IMAGE_STATUS_MAP = Object.freeze({
  403: '/images/error/undraw_wireframing_nxyi.svg',
  404: '/images/error/undraw_page_not_found_su7k.svg',
  500: '/images/error/undraw_server_down_s4lk.svg',
});

const ErrorPage = ({ errorNumber, text, imageSrc, title }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const imgSrc = React.useMemo(() => {
    if (!imageSrc && errorNumber) return IMAGE_STATUS_MAP[errorNumber];

    return imageSrc;
  }, [imageSrc, errorNumber]);

  return (
    <Page className={classes.root} title={`Error ${errorNumber}`}>
      <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'}>
        {errorNumber}: {t(title)}
      </Typography>
      <Typography align="center" variant="subtitle2">
        {t(text)}
      </Typography>
      <div className={classes.imageContainer}>
        <img alt="" className={classes.image} src={imgSrc} />
      </div>
      {isLoggedIn && (
        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            component={RouterLink}
            to="/"
            variant="outlined"
          >
            {t('Back to home')}
          </Button>
        </div>
      )}
    </Page>
  );
};

export default ErrorPage;

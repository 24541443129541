import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
  Button,
  TextField,
  makeStyles,
} from '@material-ui/core';
import lodash from 'lodash';

import PasswordField, {
  PasswordType,
  usePasswordsValidation,
} from 'src/components/PasswordField';
import { Form, AuthError } from 'src/components/AuthForm';
import useSetState from 'src/hooks/useSetState';
import useAsyncDispatch from 'src/hooks/useAsyncDispatch';

import { firstLoginRequest } from '../requestHelper';

const useStyles = makeStyles(({ spacing, typography }) => ({
  title: {
    fontWeight: 'bold',
  },
  submitButton: {
    height: 48,
    marginTop: spacing(3),
    textTransform: 'none',
    width: '100%',
    fontWeight: typography.fontWeightMedium,
    fontSize: 18,
  },
  titleBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: spacing(3),
  },
}));

const REQUIRED_ATTRIBUTES_PATH = 'challengeParameters.requiredAttributes';
const NAME_ATTRIBUTE = 'user_attributes.name';

export default ({ value, action }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isLoading, asyncDispatch] = useAsyncDispatch();
  const [error, setError] = React.useState(null);

  const requiredAttributes = lodash.get(
    value?.loginData,
    REQUIRED_ATTRIBUTES_PATH,
    []
  );
  const isFullNameRequired = requiredAttributes.includes(NAME_ATTRIBUTE);

  const [passwordData, setPasswordData] = useSetState({
    confirmationPassword: '',
    newPassword: '',
    ...(isFullNameRequired ? { fullName: '' } : {}),
  });

  const [passwordsState, validatePasswords] = usePasswordsValidation();

  const handleChange = ({ target }) => {
    const { name, value } = target;

    if (name === 'fullName')
      setPasswordData({
        [name]: value,
      });
    else
      setPasswordData({
        [name]: value?.trim(),
      });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setError(null);

    const isWithErrors = validatePasswords({
      [PasswordType.New]: passwordData.newPassword,
      [PasswordType.Confirmation]: passwordData.confirmationPassword,
    });

    if (isWithErrors) return;

    asyncDispatch(async () => {
      try {
        await firstLoginRequest(
          value.credentials,
          value.loginData,
          passwordData
        );

        action.goToSignIn();
      } catch (error) {
        setError(error);
        console.error(error);
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid item>
        <Typography component="h1" variant="h3" className={classes.title}>
          {t('Create password')}
        </Typography>
      </Grid>
      <Grid item className={classes.titleBlock}>
        <Typography
          component="h3"
          variant="h4"
          className={classes.signInFormTitle}
        >
          {t('You are required to set up a new password.')}
        </Typography>
      </Grid>
      <AuthError error={error} />
      <Grid>
        {isFullNameRequired && (
          <TextField
            required
            disabled={isLoading}
            autoComplete="username"
            type="text"
            label={t('Full name')}
            name="fullName"
            variant="outlined"
            fullWidth
            value={passwordData.fullName}
            onChange={handleChange}
          />
        )}
        <PasswordField
          showEye
          required
          disabled={isLoading}
          autoComplete="new-password"
          label={t('New password')}
          value={passwordData.newPassword}
          name="newPassword"
          onChange={handleChange}
          error={!!passwordsState.errors[PasswordType.New]}
          helperText={t(passwordsState.errors[PasswordType.New])}
        />
        <PasswordField
          showEye
          required
          disabled={isLoading}
          autoComplete="new-password"
          label={t('Confirm new password')}
          name="confirmationPassword"
          value={passwordData.confirmationPassword}
          onChange={handleChange}
          error={!!passwordsState.errors[PasswordType.Confirmation]}
          helperText={t(passwordsState.errors[PasswordType.Confirmation])}
        />
      </Grid>
      <Grid item>
        <Button
          className={classes.submitButton}
          disabled={isLoading}
          variant="contained"
          color="primary"
          type="submit"
        >
          {t('Set up password')}
        </Button>
      </Grid>
    </Form>
  );
};

import { API_URL_LEDGER } from 'src/config/environment';
import { Endpoint, Request } from 'src/lib/request';
import {
  pipe,
  errorRequestMixin,
  authRequestMixin,
  tenantRequestMixin,
  tokenRequestMixin,
} from 'src/api/mixin';

const LedgerRequest = pipe(Request)
  .chain(tenantRequestMixin)
  .chain(authRequestMixin)
  .chain(errorRequestMixin)
  .chain(tokenRequestMixin)
  .value();

const ledgerRequest = new LedgerRequest({ host: API_URL_LEDGER });
const ledgerEndpoint = new Endpoint(ledgerRequest);

export default ledgerEndpoint;

import React from 'react';

import apiErrorService from 'src/service/ApiErrorService';

import useErrorHandler from './effects/useErrorHandler';

export default () => {
  const handleError = useErrorHandler();

  React.useEffect(() => apiErrorService.subscribe(handleError), []);

  return null;
};

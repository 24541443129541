export const ROUNDED_BORDER_RADIUS = 8;

export default {
  root: {
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
    flexDirection: 'column',
    '& [class*="elevation"]': {
      boxShadow: 'none',
    },
  },
  elevation1: {
    boxShadow: '0px 0px 16px rgba(0, 0, 15, 0.05)',
  },
  elevation8: {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4);',
  },
  rounded: {
    borderRadius: ROUNDED_BORDER_RADIUS,
  },
};

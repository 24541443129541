import * as IdentifierApi from 'src/api/interfaces/identifier';

export const updatePasswordRequest = (username, passwordData) =>
  IdentifierApi.updatePassword({
    username,
    password: passwordData.newPassword,
    confirmationCode: passwordData.resetCode,
  });

export const resetPasswordRequest = username =>
  IdentifierApi.resetPassword(username);

import ledger from './ledger';
import asyncLedger from './asyncLedger';
import sites from './sites';
import identifier from './identifier';
import ums from './ums';
import claim from './claim';
import replit from './replit';

export default class Endpoint {
  static LEDGER = 'ledger';
  static ASYNC_LEDGER = 'async-ledger';
  static SITES = 'sites';
  static IDENTIFIER = 'identifier';
  static UMS = 'ums';
  static CLAIM_MANAGEMENT = 'claim-management';
  static REPLIT = 'replit';

  static endpoints = new Map([
    [Endpoint.LEDGER, ledger],
    [Endpoint.ASYNC_LEDGER, asyncLedger],
    [Endpoint.SITES, sites],
    [Endpoint.IDENTIFIER, identifier],
    [Endpoint.UMS, ums],
    [Endpoint.CLAIM_MANAGEMENT, claim],
    [Endpoint.REPLIT, replit],
  ]);

  static get(name) {
    return Endpoint.endpoints.get(name);
  }
}

import React from 'react';
import { MuiThemeProvider } from '@material-ui/core';

import { createTheme } from 'src/theme';

export default ({ children }) => {
  const theme = createTheme();

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

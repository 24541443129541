export default Target =>
  class extends Target {
    _statusEndpoint = null;

    constructor({ statusEndpoint, ...args }) {
      super(args);

      this._statusEndpoint = statusEndpoint;
    }

    _waitAsyncRequest(id, interval = 1000) {
      return new Promise((resolve, reject) => {
        const waitForRequest = () => {
          super
            .doRaw('GET', `${this._statusEndpoint}/${id}`)
            .then(({ data }) => {
              switch (data.status) {
                case 'pending':
                  return setTimeout(waitForRequest, interval);
                case 'success':
                  return resolve(data);
                default:
                  throw new Error(`Unknown status: ${data.status}`);
              }
            })
            .catch(() => {
              reject(
                new Error(
                  `Operation processing took too long or error happened. Please contact with support.`
                )
              );
            });
        };

        setTimeout(waitForRequest, interval);
      });
    }

    async doRaw(method, url, body, config) {
      const { data } = await super.doRaw(method, url, body, config);

      return this._waitAsyncRequest(data.requestId, config?.waitInterval);
    }
  };

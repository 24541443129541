const DEFAULT_CONTENT_TYPE = 'application/octet-stream';

export default Target =>
  class extends Target {
    download(method, url, params, config) {
      return this.doRaw(method, url, params, config).then(({ data }) => {
        const { filename, contents, contentType } = data;

        if (!contents) throw new Error('File content is missing');

        const url = `data:${contentType ??
          DEFAULT_CONTENT_TYPE};base64,${contents}`;

        this.saveFile(filename, url, contentType);
      });
    }
  };

export const menuItemsRoutes = {
  dashboard: ['/home'],
  quotes: [
    '/quotes/list',
    '/dashboard/list/quotes-in-referral',
    '/dashboard/list/renewal-quotes',
  ],
  policies: [
    '/policy/list',
    '/debt-policy/list',
    '/dashboard/list/renewal-policies',
  ],
  invoicing: [
    '/invoice/allocations',
    '/invoice/payments',
    '/invoice/consolidated/list',
  ],
  claims: [
    '/claim/list/all',
    '/claim/event/list',
    '/claim/partner/list',
    '/claim/task/list/all',
  ],
  reporting: [
    '/report/list/payment-bordereau',
    '/quick-sight',
    '/broker-statement',
  ],
  tools: [
    '/brokers/list',
    '/broker-merge',
    '/binder/list',
    '/schemas',
    '/bdx-import',
    '/claim-import',
    '/high-risk/list',
    '/endorsement-library/list',
    '/adjustments-library/list',
    '/make-model/list',
    '/user/list',
    '/user-import',
    '/feature/list',
  ],
};

import { Initializer } from 'src/lib/initializeManager';
import { getProductFeatures } from 'src/api/interfaces/featuresSite';

class ProductFeatureService extends Initializer {
  #features = null;

  async initialize() {
    this.#features = await getProductFeatures();
  }

  isWithSimpleQuote(product) {
    return !!this.#features[product]?.['simple_quote'];
  }

  isWithIssueDialog(product) {
    return !!this.#features[product]?.['quote_issue_popup'];
  }

  dispose() {
    this.#features = null;
  }
}

export default new ProductFeatureService();

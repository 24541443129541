import { LOCAL_TENANT } from 'src/config/environment';
import * as SitesApi from 'src/api/interfaces/sites';
import { Initializer } from 'src/lib/initializeManager';

class TenantTagService extends Initializer {
  #tenantTag = null;
  #brokerTag = null;
  #redirectUrl = null;

  async initialize() {
    if (LOCAL_TENANT) this.#tenantTag = LOCAL_TENANT;
    else {
      const { tenantId, broker, redirectUrl } = await SitesApi.getTenantTag();

      this.#tenantTag = tenantId;
      this.#brokerTag = broker;
      this.#redirectUrl = redirectUrl;
    }
  }

  get tenantTag() {
    return this.#tenantTag;
  }

  get brokerTag() {
    return this.#brokerTag;
  }

  get redirectUrl() {
    return this.#redirectUrl;
  }

  dispose() {
    this.#tenantTag = null;
  }
}

export default new TenantTagService();

import { Initializer } from 'src/lib/initializeManager';
import { getFeatureByName } from 'src/api/interfaces/featuresSite';

const QUOTE_CREATE_EMPTY_KEY = 'quote_create_empty';

class PrivateFeatureService extends Initializer {
  #schemaValidation = null;
  #quoteCreate = null;

  async initialize() {
    const [schemaValidation, quoteCreate] = await Promise.all([
      getFeatureByName('json_schema_validation'),
      getFeatureByName('quote_create'),
    ]);

    this.#schemaValidation = schemaValidation;
    this.#quoteCreate = quoteCreate;
  }

  get schemaValidation() {
    return this.#schemaValidation;
  }

  get isQuoteCreateEmpty() {
    return !!this.#quoteCreate?.[QUOTE_CREATE_EMPTY_KEY];
  }

  dispose() {
    this.#schemaValidation = null;
  }
}

export default new PrivateFeatureService();

/* eslint-disable no-control-regex */
const DISPOSITION_TYPE_REGEXP = /^([!#$%&'*+.0-9A-Z^_`a-z|~-]+)[\x09\x20]*(?:$|;)/;
const PARAM_REGEXP = /;[\x09\x20]*([!#$%&'*+.0-9A-Z^_`a-z|~-]+)[\x09\x20]*=[\x09\x20]*("(?:[\x20!\x23-\x5b\x5d-\x7e\x80-\xff]|\\[\x20-\x7e])*"|[!#$%&'*+.0-9A-Z^_`a-z|~-]+)[\x09\x20]*/g;
const QESC_REGEXP = /\\([\u0000-\u007f])/g;
const EXT_VALUE_REGEXP = /^([A-Za-z0-9!#$%&+\-^_`{}~]+)'(?:[A-Za-z]{2,3}(?:-[A-Za-z]{3}){0,3}|[A-Za-z]{4,8}|)'((?:%[0-9A-Fa-f]{2}|[A-Za-z0-9!#$&+.^_`|~-])+)$/;
const HEX_ESCAPE_REPLACE_REGEXP = /%([0-9A-Fa-f]{2})/g;

// INFO: Following code is extracted from https://github.com/jshttp/content-disposition
const decodefield = value => {
  const match = EXT_VALUE_REGEXP.exec(value);

  if (!match) return value;

  const charset = match[1].toLowerCase();
  const encoded = match[2];

  const decoder = new TextDecoder(charset);
  const field = encoded.replace(HEX_ESCAPE_REPLACE_REGEXP, (_, hex) =>
    String.fromCharCode(parseInt(hex, 16))
  );
  const binary = Uint8Array.from(Array.from(field, char => char.charCodeAt()));

  return decoder.decode(binary);
};

export const parseContentDisposition = header => {
  let match = DISPOSITION_TYPE_REGEXP.exec(header);

  let key;
  const names = [];
  const params = {};
  let value;

  // match parameters
  while ((match = PARAM_REGEXP.exec(header))) {
    key = match[1].toLowerCase();
    value = match[2];

    names.push(key);

    if (key.indexOf('*') + 1 === key.length) {
      key = key.slice(0, -1);
      value = decodefield(value);

      // overwrite existing value
      params[key] = value;
      continue;
    }

    if (typeof params[key] === 'string') continue;

    // remove quotes and escapes
    if (value[0] === '"')
      value = value.substring(1, value.length - 1).replace(QESC_REGEXP, '$1');

    params[key] = value;
  }

  return params;
};

export const getFileName = contentDispositionValue => {
  const { filename } = parseContentDisposition(contentDispositionValue);

  return decodeURI(filename);
};

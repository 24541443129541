import React from 'react';

import MfaCard from 'src/containers/MfaPage/MfaCard';
import * as IdentifierApi from 'src/api/interfaces/identifier';
import Dialog from 'src/components/Dialog';

const DIALOG_TITLE = 'MFA is required and to proceed, you need to set it up';

export default ({ value, action }) => {
  const { credentials, loginData } = value;

  const abortSetup = () => {
    action.setLoginData(null);
    action.goToSignIn();
  };

  const handleSubmit = async (mfaCode, event, session) => {
    event.preventDefault();

    try {
      await IdentifierApi.verifyMfa(mfaCode, session);

      action.goToSignIn();
    } catch (error) {
      abortSetup();
    }
  };

  return (
    <Dialog
      open
      fullWidth
      onClose={abortSetup}
      title={DIALOG_TITLE}
      hideActions
    >
      <MfaCard
        username={credentials.username}
        session={loginData?.data?.session}
        handleSubmitSecureCode={handleSubmit}
      />
    </Dialog>
  );
};

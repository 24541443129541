import InitializeManager from 'src/lib/initializeManager';

class InitializationService {
  #public = new InitializeManager();
  #private = new InitializeManager();

  get public() {
    return this.#public;
  }
  get private() {
    return this.#private;
  }
}

export default new InitializationService();

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Drawer, makeStyles } from '@material-ui/core';

const DRAWER_EXPANDED_WIDTH = 240;
const DRAWER_COLLAPSED_WIDTH = 88;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: DRAWER_EXPANDED_WIDTH,
    position: 'relative',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
  },
  paper: {
    border: 'none',
    backgroundColor: theme.color.base.brand.regular,
    overflowX: 'hidden',
  },
  drawerOpen: {
    width: DRAWER_EXPANDED_WIDTH,
    padding: '20px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: DRAWER_COLLAPSED_WIDTH,
    padding: '20px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const MiniDrawer = ({ expanded, onToggle, anchor, className, children }) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor={anchor}
      open={expanded}
      onClose={onToggle}
      variant="permanent"
      className={clsx(
        classes.drawer,
        {
          [classes.drawerOpen]: expanded,
          [classes.drawerClose]: !expanded,
        },
        className
      )}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerOpen]: expanded,
          [classes.drawerClose]: !expanded,
        }),
      }}
    >
      {children}
    </Drawer>
  );
};

MiniDrawer.propTypes = {
  expanded: PropTypes.bool,
  onToggle: PropTypes.func,
  anchor: PropTypes.oneOf(['left', 'top', 'right', 'bottom']),
  className: PropTypes.string,
};

MiniDrawer.defaultProps = {
  expanded: true,
};

export default MiniDrawer;

import * as EndorsementApi from 'src/api/interfaces/endorsement';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants';
import { convertKeysToCamelCase } from 'src/helpers/objects';

/** initial state */
const initialState = {
  list: null,
  page: 1,
  rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  totalCount: 0,
};

/** selectors */
export const selectEndorsementLibraryList = state => state.endorsement.list;

export const selectEndorsementLibraryPaginationData = state => {
  const { page, rowsPerPage, totalCount } = state.endorsement;

  return {
    page,
    rowsPerPage,
    totalCount,
  };
};

/** action types */
const prefix = 'endorsement-library';

const ActionTypes = {
  FETCH_LIST: `${prefix}/FETCH_LIST`,
  FETCH_LIST_SUCCESS: `${prefix}/FETCH_LIST_SUCCESS`,
  FETCH_LIST_FAIL: `${prefix}/FETCH_LIST_FAIL`,
};

/** actions */
export function fetchEndorsementLibraryListData(
  page = 1,
  perPage = DEFAULT_ROWS_PER_PAGE,
  filters
) {
  return async dispatch => {
    try {
      dispatch({ type: ActionTypes.FETCH_LIST });

      const data = await EndorsementApi.getEndorsementsList({
        page,
        perPage,
        filters,
      });
      const convertedData = convertKeysToCamelCase(data);

      dispatch({
        type: ActionTypes.FETCH_LIST_SUCCESS,
        payload: convertedData,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.FETCH_LIST_FAIL,
      });
    }
  };
}

/** default reducer */
export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.FETCH_LIST:
      return {
        ...state,
        list: null,
      };
    case ActionTypes.FETCH_LIST_SUCCESS:
      return {
        ...state,
        list: payload.data,
        page: payload.currentPage,
        rowsPerPage: payload.perPage,
        totalCount: payload.total,
      };
    case ActionTypes.FETCH_LIST_FAIL:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';

import useIconMask from '../useIconMask';

export default React.forwardRef(
  (
    {
      onToggle,
      icon: Icon,
      title,
      open,
      classes,
      children,
      isActive,
      href,
      ...props
    },
    ref
  ) => {
    const location = useLocation();
    const { t } = useTranslation();
    const iconMaskStyles = useIconMask(Icon);

    const handleArrowClick = event => {
      event.preventDefault();
      event.stopPropagation();

      onToggle({ disableNavigation: true });
    };

    return (
      <div>
        <ButtonBase
          className={clsx(classes.button, {
            [classes.active]: isActive && isActive(null, location),
          })}
          onClick={onToggle}
          to={href}
          component={href && NavLink}
          {...props}
        >
          {Icon && (
            <div className={classes.iconContainer}>
              <div className={clsx(classes.icon, iconMaskStyles.iconMask)} />
            </div>
          )}
          <div className={classes.truncate} ref={ref}>
            {t(title)}
          </div>
          {open ? (
            <ExpandLessIcon
              onClick={handleArrowClick}
              className={classes.expandIcon}
              color="inherit"
            />
          ) : (
            <ExpandMoreIcon
              onClick={handleArrowClick}
              className={classes.expandIcon}
              color="inherit"
            />
          )}
        </ButtonBase>
        <Collapse in={open}>{children}</Collapse>
      </div>
    );
  }
);

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import leapYearAddPlugin from 'src/helpers/dayjs/leapYearAddPlugin';

dayjs
  .extend(isSameOrBefore)
  .extend(isSameOrAfter)
  .extend(isBetween)
  .extend(isLeapYear)
  .extend(duration)
  .extend(localizedFormat)
  .extend(utc)
  .extend(timezone)
  .extend(leapYearAddPlugin);

import { ledger } from 'src/api/endpoint';

export async function search(searchTerm, page, limit) {
  return ledger.get('/brokers/search', { searchTerm, page, limit });
}

export async function getBroker(shortName) {
  return ledger.get(`/brokers/${encodeURIComponent(shortName)}`);
}

export async function insert(data) {
  return ledger.post('/brokers', data);
}

export async function edit(shortName, data) {
  return ledger.put(`/brokers/${encodeURIComponent(shortName)}`, data);
}

export async function getBrokerShortNames(params = {}) {
  return ledger.get('/brokers/short-names', params);
}

export function getBrokerCurrencies(broker) {
  return ledger.get('/brokers/currencies', { broker });
}

export function getActiveBrokersByScheme(schemaName, isRenewal) {
  return ledger.get(`/product-brokers/${schemaName}/active`, { isRenewal });
}

export function getBrokerData(shortName) {
  return ledger.get(`/broker-data/${encodeURIComponent(shortName)}`);
}

export function getBrokerMergePoliciesCount(acquiredBroker) {
  return ledger.get(
    `/brokers/merge/${encodeURIComponent(acquiredBroker)}/policies-count`
  );
}

export function startBrokerMerge(brokers) {
  return ledger.post('/brokers/merge', {
    ...brokers,
  });
}

export default class Download {
  #request = null;

  constructor(request) {
    this.#request = request;
  }

  get(url, params, config) {
    return this.#request.download('GET', url, params, config);
  }

  post(url, params, config) {
    return this.#request.download('POST', url, params, config);
  }

  put(url, params, config) {
    return this.#request.download('PUT', url, params, config);
  }

  patch(url, params, config) {
    return this.#request.download('PATCH', url, params, config);
  }

  delete(url, params, config) {
    return this.#request.download('DELETE', url, params, config);
  }
}

export const throttle = (callback, delay) => {
  let timeoutId = -1;

  const start = (...args) => {
    if (timeoutId > 0) return;

    callback(...args);

    timeoutId = setTimeout(() => {
      timeoutId = -1;
    }, delay);
  };

  const stop = () => {
    clearTimeout(timeoutId);
    timeoutId = -1;
  };

  return {
    start,
    stop,
  };
};

export const timeout = (callback, delay) => {
  let timeoutId = -1;

  const start = (...args) => {
    callback(...args);
    timeoutId = setTimeout(start, delay, ...args);
  };

  const stop = () => {
    clearTimeout(timeoutId);
    timeoutId = -1;
  };

  return {
    start,
    stop,
  };
};

import lodash from 'lodash';

export default filters =>
  Object.entries(filters ?? {}).reduce((filterProps, [name, value]) => {
    const filterName = `filter[${lodash.snakeCase(name)}]`;
    const filterValue = String(value);

    return {
      ...filterProps,
      [filterName]: filterValue,
    };
  }, {});

import { REPLIT_APP_URL } from 'src/config/environment';
import { Endpoint, Request } from 'src/lib/request';
import { pipe, errorRequestMixin } from 'src/api/mixin';

const ReplitRequest = pipe(Request)
  .chain(errorRequestMixin)
  .value();

const replitRequest = new ReplitRequest({ host: REPLIT_APP_URL });
const replitEndpoint = new Endpoint(replitRequest);

export default replitEndpoint;

import { API_URL_CLAIM_MANAGEMENT } from 'src/config/environment';
import { Request, Endpoint } from 'src/lib/request';
import { pipe, tenantRequestMixin, errorRequestMixin } from 'src/api/mixin';

const ClaimPublicRequest = pipe(Request)
  .chain(tenantRequestMixin)
  .chain(errorRequestMixin)
  .value();

const request = new ClaimPublicRequest({ host: API_URL_CLAIM_MANAGEMENT });
const endpoint = new Endpoint(request);

export default endpoint;

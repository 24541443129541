import lodash from 'lodash';
import { createTheme as createMuiTheme } from '@material-ui/core';

import publicFeatureService from 'src/service/PublicFeatureService';

import typography from './typography';
import overrides from './overrides';
import palette from './palette';
import mixin from './mixin';
import defaultTheme from './defaultTheme.json';

const baseTheme = {
  palette,
  typography,
  overrides,
  mixin,
};

export const createTheme = () => {
  const theme = createMuiTheme(baseTheme);
  const { global, component, list } = publicFeatureService.theme;

  return {
    ...theme,
    ...global,
    component,
    list,
  };
};

export const normalizeTheme = theme => {
  if (!theme) return defaultTheme;

  return lodash.mergeWith(defaultTheme, theme, (_, source) => {
    if (Array.isArray(source)) return source;
  });
};

export default defaultTheme;

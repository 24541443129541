import AccessActions from 'src/accessActions';
import InsertPolicyButton from 'src/components/InsertPolicyButton';
import InsertClaimButton from 'src/components/InsertClaimButton';

import InvoicingIcon from './menuIcons/invoicing.svg';
import ReportingIcon from './menuIcons/reporting.svg';
import PoliciesIcon from './menuIcons/policies.svg';
import PolicyDraftIcon from './menuIcons/policies_draft.svg';
import PlusSignRoundedIcon from './menuIcons/plus_sign_rounded.svg';
import ToolsIcon from './menuIcons/tools.svg';
import CustomerIcon from './menuIcons/customer.svg';
import ClaimsIcon from './menuIcons/claims.svg';
import DashboardIcon from './menuIcons/dashboard.svg';
import { menuItemsRoutes } from './menuItemsRoutes';

const isMenuItemActive = (location, menuItemRoutes) =>
  menuItemRoutes.some(route => location?.pathname?.startsWith(route));

export default {
  items: [
    {
      title: 'New quote',
      component: InsertPolicyButton,
      icon: PlusSignRoundedIcon,
      resourceTypes: [AccessActions.QuoteCreate],
    },
    {
      title: 'Dashboard',
      href: '/home',
      icon: DashboardIcon,
      isActive: (_, location) =>
        isMenuItemActive(location, menuItemsRoutes.dashboard),
    },
    {
      title: 'Quotes',
      href: '/quotes/list',
      icon: PolicyDraftIcon,
      resourceTypes: [AccessActions.QuotesList],
      isActive: (_, location) =>
        isMenuItemActive(location, menuItemsRoutes.quotes),
      items: [
        {
          title: 'All Quotes',
          href: '/quotes/list',
          resourceTypes: [
            AccessActions.DashboardQuotesInReferral,
            AccessActions.DashboardQuotesRenewal,
          ],
        },
        {
          title: 'Referrals',
          href: '/dashboard/list/quotes-in-referral',
          resourceTypes: [AccessActions.DashboardQuotesInReferral],
        },
        {
          title: 'Renewal quotes',
          href: '/dashboard/list/renewal-quotes',
          resourceTypes: [AccessActions.DashboardQuotesRenewal],
        },
      ],
    },
    {
      title: 'Policies',
      href: '/policy/list',
      icon: PoliciesIcon,
      resourceTypes: [AccessActions.PoliciesList],
      isActive: (_, location) =>
        isMenuItemActive(location, menuItemsRoutes.policies),
      items: [
        {
          title: 'All Policies',
          href: '/policy/list',
          resourceTypes: [
            AccessActions.DebtPoliciesList,
            AccessActions.DashboardPoliciesRenewal,
          ],
        },
        {
          title: 'Policies in debt',
          href: '/debt-policy/list',
          resourceTypes: [AccessActions.DebtPoliciesList],
        },
        {
          title: 'Upcoming renewals',
          href: '/dashboard/list/renewal-policies',
          resourceTypes: [AccessActions.DashboardPoliciesRenewal],
        },
      ],
    },
    {
      title: 'Customers',
      href: '/customer/list',
      icon: CustomerIcon,
      resourceTypes: [AccessActions.CustomersList],
    },
    {
      title: 'Invoicing',
      icon: InvoicingIcon,
      resourceTypes: [AccessActions.InvoicesList],
      href: '/invoice/allocations',
      isActive: (_, location) =>
        isMenuItemActive(location, menuItemsRoutes.invoicing),
      items: [
        {
          title: 'All Invoices',
          href: '/invoice/allocations',
          resourceTypes: [
            AccessActions.PaymentsList,
            AccessActions.ConsolidatedInvoicesList,
          ],
        },
        {
          title: 'Payments',
          href: '/invoice/payments',
          resourceTypes: [AccessActions.PaymentsList],
        },
        {
          title: 'Consolidated',
          href: '/invoice/consolidated/list',
          resourceTypes: [AccessActions.ConsolidatedInvoicesList],
        },
      ],
    },
    {
      title: 'Claims',
      icon: ClaimsIcon,
      resourceTypes: [AccessActions.ClaimShow],
      href: '/claim/list/all',
      isActive: (_, location) =>
        isMenuItemActive(location, menuItemsRoutes.claims),
      items: [
        {
          title: 'All Claims',
          href: '/claim/list/all',
          resourceTypes: [
            AccessActions.ClaimCreate,
            AccessActions.ClaimEventList,
            AccessActions.ClaimPartnerList,
            AccessActions.ClaimTaskList,
          ],
        },
        {
          title: 'New Claim',
          component: InsertClaimButton,
          resourceTypes: [AccessActions.ClaimCreate],
        },
        {
          title: 'Major events',
          href: '/claim/event/list',
          resourceTypes: [AccessActions.ClaimEventList],
        },
        {
          title: 'Partners',
          href: '/claim/partner/list',
          resourceTypes: [AccessActions.ClaimPartnerList],
        },
        {
          title: 'Tasks',
          href: '/claim/task/list/all',
          resourceTypes: [AccessActions.ClaimTaskList],
        },
      ],
    },
    {
      title: 'Reporting',
      icon: ReportingIcon,
      isActive: (_, location) =>
        isMenuItemActive(location, menuItemsRoutes.reporting),
      items: [
        {
          title: 'Payment bordereau',
          href: '/report/list/payment-bordereau',
          resourceTypes: [AccessActions.PaymentReportsList],
        },
        {
          title: 'Analytics',
          href: '/quick-sight',
          resourceTypes: [AccessActions.ReportsQSSessionEmbed],
        },
        {
          title: 'Broker statement',
          href: '/broker-statement',
          resourceTypes: [AccessActions.BrokerStatementDownload],
        },
      ],
    },
    {
      title: 'Tools',
      icon: ToolsIcon,
      isActive: (_, location) =>
        isMenuItemActive(location, menuItemsRoutes.tools),
      items: [
        {
          title: 'Brokers',
          href: '/brokers/list',
          resourceTypes: [AccessActions.BrokersList],
        },
        {
          title: 'Broker merge',
          href: '/broker-merge',
          resourceTypes: [AccessActions.BrokerMerge],
        },
        {
          title: 'Binders',
          href: '/binder/list',
          resourceTypes: [AccessActions.BinderList],
        },
        {
          title: 'Schema constructor',
          href: '/schemas',
          resourceTypes: [AccessActions.SchemesList],
        },
        {
          title: 'Policy import',
          href: '/bdx-import',
          resourceTypes: [AccessActions.PoliciesImport],
        },
        {
          title: 'Claim import',
          href: '/claim-import',
          resourceTypes: [AccessActions.ImportsCreate],
        },
        {
          title: 'High risks',
          href: '/high-risk/list',
          resourceTypes: [AccessActions.HighRisksList],
        },
        {
          title: 'Endorsement library',
          href: '/endorsement-library/list',
          resourceTypes: [AccessActions.EndorsementLibrary],
        },
        {
          title: 'Policy Adjustments Library',
          href: '/adjustments-library/list',
          resourceTypes: [AccessActions.AdjustmentsLibrary],
        },
        {
          title: 'Make Model table',
          href: '/make-model/list',
          resourceTypes: [AccessActions.MakeModelList],
        },
        {
          title: 'Users',
          href: '/user/list',
          resourceTypes: [AccessActions.UmsUsersList],
        },
        {
          title: 'User Import',
          href: '/user-import',
          resourceTypes: [AccessActions.UmsUserImport],
        },
        {
          title: 'Features management',
          href: '/feature/list',
          resourceTypes: [AccessActions.FeatureList],
        },
      ],
    },
  ],
};

import { makeStyles } from '@material-ui/core';

import { HOME_PAGE } from 'src/constants/pages';

const getBackgroundImageUrl = (location, resources) => {
  if (location.pathname === HOME_PAGE) {
    if (resources.page?.home?.head?.backgroundImage)
      return resources.page.home.head.backgroundImage;

    return resources.page.home.dashboard.backgroundImage;
  }

  return resources.background.image;
};

export default makeStyles(({ breakpoints, resources }) => ({
  layout: {
    height: '100vh',
    display: 'flex',
    overflow: 'hidden',
  },
  layoutContainer: {
    position: 'relative',
    minHeight: '100vh',
    flex: '1 1 auto',
    flexDirection: 'column',
    backgroundImage: location =>
      `url(${getBackgroundImageUrl(location, resources)})`,
    backgroundRepeat: resources.background.repeat,
    backgroundPosition: resources.background.position,
    backgroundColor: resources.background.color,
    backgroundSize: resources.background.size,
    display: 'flex',
    overflow: 'auto',
    maxWidth: '100%',
    [breakpoints.down('xs')]: {
      paddingTop: 56,
    },
  },
}));

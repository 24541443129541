import React from 'react';

import notify from 'src/service/NotifyService';

const WARNING_MESSAGE =
  "You haven't been active for a while and will be logged out soon!";

export default () => {
  const closeRef = React.useRef(null);

  const showNote = () => {
    if (!!closeRef.current) return;

    notify.warning(WARNING_MESSAGE, {
      persist: true,
      afterShow: close => {
        closeRef.current = close;
      },
    });
  };

  const hideNote = () => {
    if (!closeRef.current) return;

    closeRef.current();
    closeRef.current = null;
  };

  return {
    showNote,
    hideNote,
  };
};

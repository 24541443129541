import React from 'react';

import ResetPassword from './ResetPassword';
import UpdatePassword from './UpdatePassword';

const Step = {
  ResetPassword: 'resetPassword',
  UpdatePassword: 'updatePassword',
};

const stepMap = new Map([
  [Step.ResetPassword, ResetPassword],
  [Step.UpdatePassword, UpdatePassword],
]);

export default () => {
  const [activeStep, setActiveStep] = React.useState(Step.ResetPassword);

  const goToResetPassword = () => setActiveStep(Step.ResetPassword);
  const goToUpdatePassword = () => setActiveStep(Step.UpdatePassword);

  const [username, setUsername] = React.useState(null);

  const action = {
    goToResetPassword,
    goToUpdatePassword,
    setUsername,
  };

  const value = {
    username,
  };

  const ActiveStep = stepMap.get(activeStep);
  const props = { value, action };

  return <ActiveStep {...props} />;
};

import { loadIntercomScript, getIntercomHash } from 'src/helpers/intercom';

import tenantTagService from './TenantTagService';

export default class IntercomService {
  #settings = null;

  constructor(settings) {
    this.#settings = settings;
  }

  async boot(userInfo) {
    const {
      config: { app_id },
      settings,
    } = this.#settings;

    const { name, email } = userInfo;

    const { tenantTag } = tenantTagService;

    window.intercomSettings = {
      app_id,
      name,
      email,
      user_hash: await getIntercomHash(email),
      company: tenantTag,
      ...settings,
    };

    loadIntercomScript(app_id);
  }

  trackEvent(eventName, metadata) {
    window.Intercom('trackEvent', eventName, metadata);
  }

  dispose() {
    window.Intercom('shutdown');
    window.intercomSettings = null;
  }
}

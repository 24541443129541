import * as Api from 'src/api/report';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants';

import { ActionTypes as AuthActionTypes } from './auth';

/** initial state */
const initialState = {
  fields: [],
  report: null,
  reportList: [],
  paymentBordereauList: [],
  page: 1,
  rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  totalCount: 0,
};

/** selectors */
export const selectReportFields = state => state.report.fields;
export const selectReport = state => state.report.report;
export const selectReportList = state => state.report.reportList;

/** action types */
const prefix = 'report';
const ActionTypes = {
  FETCH_FIELD_LIST: `${prefix}/FETCH_FIELD_LIST`,
  FETCH_FIELD_LIST_SUCCESS: `${prefix}/FETCH_FIELD_LIST_SUCCESS`,
  FETCH_FIELD_LIST_FAIL: `${prefix}/FETCH_FIELD_LIST_FAIL`,
  SAVE_REPORT: `${prefix}/SAVE_REPORT`,
  SAVE_REPORT_SUCCESS: `${prefix}/SAVE_REPORT_SUCCESS`,
  SAVE_REPORT_FAIL: `${prefix}/SAVE_REPORT_FAIL`,
  GET_REPORT: `${prefix}/GET_REPORT`,
  GET_REPORT_SUCCESS: `${prefix}/GET_REPORT_SUCCESS`,
  GET_REPORT_FAIL: `${prefix}/GET_REPORT_FAIL`,
  GET_REPORT_LIST: `${prefix}/GET_REPORT_LIST`,
  GET_REPORT_LIST_SUCCESS: `${prefix}/GET_REPORT_LIST_SUCCESS`,
  GET_REPORT_LIST_FAIL: `${prefix}/GET_REPORT_LIST_FAIL`,
  GET_BORDEREAU_LIST: `${prefix}/GET_BORDEREAU_LIST`,
  GET_BORDEREAU_LIST_SUCCESS: `${prefix}/GET_BORDEREAU_LIST_SUCCESS`,
  GET_BORDEREAU_LIST_FAIL: `${prefix}/GET_BORDEREAU_LIST_FAIL`,
  RESET_REPORT: `${prefix}/RESET_REPORT`,
};

/** actions */
export function fetchFieldList() {
  return async dispatch => {
    try {
      dispatch({ type: ActionTypes.FETCH_FIELD_LIST });
      const data = await Api.getFieldList();

      dispatch({ type: ActionTypes.FETCH_FIELD_LIST_SUCCESS, payload: data });
    } catch (e) {
      dispatch({
        type: ActionTypes.FETCH_FIELD_LIST_FAIL,
        payload: e.response && e.response.data.message,
      });
    }
  };
}

export function saveReport(item) {
  return async dispatch => {
    try {
      dispatch({ type: ActionTypes.SAVE_REPORT });
      const data = item.id
        ? await Api.save(item.id, item)
        : await Api.create(item);

      dispatch({ type: ActionTypes.SAVE_REPORT_SUCCESS, payload: data });
    } catch (e) {
      dispatch({
        type: ActionTypes.SAVE_REPORT_FAIL,
        payload: e.response && e.response.data.message,
      });
    }
  };
}

export function getReportList() {
  return async dispatch => {
    try {
      dispatch({ type: ActionTypes.GET_REPORT_LIST });
      const data = await Api.getReportList();

      dispatch({ type: ActionTypes.GET_REPORT_LIST_SUCCESS, payload: data });
    } catch (e) {
      dispatch({
        type: ActionTypes.GET_REPORT_LIST_FAIL,
        payload: e.response && e.response.data.message,
      });
    }
  };
}

export function getReport(id) {
  return async dispatch => {
    try {
      dispatch({ type: ActionTypes.GET_REPORT });
      const data = await Api.getReport(id);

      dispatch({ type: ActionTypes.GET_REPORT_SUCCESS, payload: data });
    } catch (e) {
      dispatch({
        type: ActionTypes.GET_REPORT_FAIL,
        payload: e.response && e.response.data.message,
      });
    }
  };
}

export function resetReport() {
  return {
    type: ActionTypes.RESET_REPORT,
  };
}

/** default reducer */
export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.FETCH_FIELD_LIST_SUCCESS:
      return {
        ...state,
        fields: payload,
      };
    case ActionTypes.SAVE_REPORT_SUCCESS:
    case ActionTypes.GET_REPORT_SUCCESS:
      return {
        ...state,
        report: {
          ...payload,
          config: {
            ...payload.config,
            columns: payload.config.columns.map(column => ({
              ...column,
              type: column.date_format ? 'date' : 'string',
            })),
          },
        },
      };
    case ActionTypes.RESET_REPORT:
      return {
        ...state,
        report: initialState.report,
      };
    case ActionTypes.GET_REPORT_LIST_SUCCESS:
      return {
        ...state,
        reportList: payload,
      };
    case ActionTypes.GET_REPORT_LIST:
    case ActionTypes.GET_REPORT_LIST_FAIL:
      return {
        ...state,
        reportList: initialState.reportList,
        page: 1,
        rowsPerPage: 0,
        totalCount: 0,
      };
    case ActionTypes.GET_BORDEREAU_LIST_SUCCESS:
      const { current_page, per_page, total } = payload.meta.pagination;

      return {
        ...state,
        paymentBordereauList: payload.data,
        page: current_page,
        rowsPerPage: per_page,
        totalCount: total,
      };
    case ActionTypes.GET_BORDEREAU_LIST:
    case ActionTypes.GET_BORDEREAU_LIST_FAIL:
      return {
        ...state,
        paymentBordereauList: initialState.reportList,
        page: 1,
        rowsPerPage: 0,
        totalCount: 0,
      };
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

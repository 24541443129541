import tenantTagService from 'src/service/TenantTagService';

import * as SchemaApi from '../schema';

export async function getSchemasList() {
  const { tenantTag } = tenantTagService;

  try {
    return await SchemaApi.getSchemasList(tenantTag);
  } catch (error) {
    throw error.response.data;
  }
}

export async function getSchemasListByType(type) {
  const { tenantTag } = tenantTagService;

  try {
    return await SchemaApi.getSchemasListByType(tenantTag, type);
  } catch (error) {
    throw error.response.data;
  }
}

export async function getLatestSchema(type, name) {
  const { tenantTag } = tenantTagService;

  try {
    return await SchemaApi.getLatestSchema(tenantTag, type, name);
  } catch (error) {
    throw error.response.data;
  }
}

export async function getLatestUserSchema() {
  const { tenantTag } = tenantTagService;

  try {
    return await SchemaApi.getLatestUserSchema(tenantTag);
  } catch (error) {
    throw error.response.data;
  }
}

export async function getLatestSchemaWithInfo(type, name) {
  const { tenantTag } = tenantTagService;

  try {
    return await SchemaApi.getLatestSchemaWithInfo(tenantTag, type, name);
  } catch (error) {
    throw error.response.data;
  }
}

export async function getVersionedSchema(type, name, version) {
  const { tenantTag } = tenantTagService;

  try {
    return await SchemaApi.getVersionedSchema(tenantTag, type, name, version);
  } catch (error) {
    throw error.response.data;
  }
}

export async function getVersionedSchemaWithInfo(type, name, version) {
  const { tenantTag } = tenantTagService;

  try {
    return await SchemaApi.getVersionedSchemaWithInfo(
      tenantTag,
      type,
      name,
      version
    );
  } catch (error) {
    throw error.response.data;
  }
}

export async function getVersionedUISchema(type, name, version) {
  const { tenantTag } = tenantTagService;

  try {
    return await SchemaApi.getVersionedUISchema(tenantTag, type, name, version);
  } catch (error) {
    throw error.response.data;
  }
}

export async function getVersionedUISchemaWithInfo(type, name, version) {
  const { tenantTag } = tenantTagService;

  try {
    return await SchemaApi.getVersionedUISchemaWithInfo(
      tenantTag,
      type,
      name,
      version
    );
  } catch (error) {
    throw error.response.data;
  }
}

export async function getLatestUISchema(type, name) {
  const { tenantTag } = tenantTagService;

  try {
    return await SchemaApi.getLatestUISchema(tenantTag, type, name);
  } catch (error) {
    throw error.response.data;
  }
}

export async function getLatestUserUISchema() {
  const { tenantTag } = tenantTagService;

  try {
    return await SchemaApi.getLatestUserUISchema(tenantTag);
  } catch (error) {
    throw error.response.data;
  }
}

export async function getLatestUISchemaWithInfo(type, name) {
  const { tenantTag } = tenantTagService;

  try {
    return await SchemaApi.getLatestUISchemaWithInfo(tenantTag, type, name);
  } catch (error) {
    throw error.response.data;
  }
}

export async function editSchema(type, name, schema) {
  const { tenantTag } = tenantTagService;

  try {
    const { data } = await SchemaApi.editSchema(tenantTag, type, name, schema);

    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function editUISchema(type, name, version, uiSchema) {
  const { tenantTag } = tenantTagService;

  try {
    return await SchemaApi.editUISchema({
      tenantTag,
      type,
      name,
      version,
      uiSchema,
    });
  } catch (error) {
    throw error.response.data;
  }
}

export async function getSchemasByType(type) {
  const { tenantTag } = tenantTagService;

  try {
    return await SchemaApi.getSchemasByType(tenantTag, type);
  } catch (error) {
    throw error.response.data;
  }
}

export async function getFeatureSchema(featureName) {
  try {
    return await SchemaApi.getFeatureSchema(featureName);
  } catch (error) {
    throw error.response.data;
  }
}

export async function getFeatureUiSchema(featureName) {
  try {
    const response = await SchemaApi.getFeatureUiSchema(featureName);

    return response;
  } catch (error) {
    throw error.response.data;
  }
}

import { ledger } from 'src/api/endpoint';

export function getBrokerPaymentsBdxUrl() {
  return '/broker-payments/bdx';
}

export function getFieldList() {
  return ledger.get('/reports/fields');
}

export function getReportList() {
  return ledger.get('/reports/configurations');
}

export function getBrokerPaymentsReceivablesUrl() {
  return `${getBrokerPaymentsBdxUrl()}/receivables`;
}

export function getAllocatedInvoicesForInsurer(
  insurer,
  reportingTime,
  searchTerm
) {
  const brokerPaymentsReceivablesUrl = getBrokerPaymentsReceivablesUrl();

  return ledger.get(brokerPaymentsReceivablesUrl, {
    insurer,
    reporting_time: reportingTime,
    search: searchTerm,
  });
}

export function getBdx(id) {
  return ledger.get(`${getBrokerPaymentsBdxUrl()}/${id}/invoices`);
}

export function getBdxInfo(id) {
  return ledger.get(`${getBrokerPaymentsBdxUrl()}/${id}`);
}

export function addInvoiceToBdx(id, data) {
  return ledger.put(`${getBrokerPaymentsBdxUrl()}/${id}/invoices`, {
    invoices: data,
  });
}

export function removeInvoiceFromBdx(id, data) {
  return ledger.delete(`${getBrokerPaymentsBdxUrl()}/${id}/invoices`, {
    invoices: data,
  });
}

export function createBDX(data) {
  return ledger.post(getBrokerPaymentsBdxUrl(), data);
}

export function payBDX(id, data) {
  return ledger.post(
    `${getBrokerPaymentsBdxUrl()}/${id}/insurer-payment`,
    data
  );
}

export function downloadBDX(id) {
  return ledger.get(
    `/reports/payment-bdx/${id}`,
    {},
    {
      responseType: 'blob',
    },
    'csv'
  );
}

export function getReport(id) {
  return ledger.get(`/reports/configurations/${id}`);
}

export function create(item) {
  return ledger.post('/reports/configurations', item);
}

export function save(id, item) {
  return ledger.put(`/reports/configurations/${id}`, item);
}

export function runReport(config, format = 'csv') {
  return ledger.post(
    '/reports/risk-bdx',
    config,
    {
      headers: {
        Accept: format === 'csv' ? 'text/csv' : 'application/json',
      },
      responseType: format === 'csv' ? 'blob' : 'json',
    },
    format
  );
}

export function getQSSessionUrl(openId) {
  return ledger.post('/reports/qs/session/embed', { openId });
}

export async function downloadBrokersStatementsReport(params) {
  return ledger.download.get(`/brokers/statements-report/download`, params);
}

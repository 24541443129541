import React from 'react';
import { isCancel } from 'axios';

import useAccessDeniedHandler from './useAccessDeniedHandler';
import useNotificationHandler from './useNotificationHandler';
import useUnauthorizedHandler from './useUnauthorizedHandler';

export default () => {
  const { current: handlersMap } = React.useRef([
    {
      check: ({ response }) => response?.status === 401,
      effect: useUnauthorizedHandler(),
    },
    {
      check: ({ response }) => response?.status === 403,
      effect: useAccessDeniedHandler(),
    },
    {
      check: ({ response }) => response?.status >= 400,
      effect: useNotificationHandler(),
    },
    {
      check: ({ response }) => !response,
      effect: useNotificationHandler(),
    },
  ]);

  return error => {
    if (!error || isCancel(error)) return null;

    const handlerItem = handlersMap.find(item => item.check(error));

    handlerItem && handlerItem.effect(error);
  };
};

export const DEFAULT_ROWS_PER_PAGE = 25;
export const DEFAULT_ROWS_PER_PAGE_OPTIONS = [25, 50, 100, 500];
export const DEFAULT_NOT_SET_VALUE = '<not set>';

export const LANGUAGES_MAP = {
  English: 'en',
  Estonian: 'et',
};

export const LOOKUP_LOCAL_STORAGE_KEY = 'i18nextLng';

export const REFERRAL_STATUSES_MAP = {
  ReferralRequired: 'referral-required',
  InManualReferral: 'in-manual-referral',
  ManualDeclined: 'manual-declined',
  ManualAccepted: 'manual-accepted',
};

export const QUOTE_STATUS_CONFIG = {
  'quote:issued': {
    title: 'Issued',
    color: '#51b873',
  },
  'quote:underwritten': {
    title: 'Underwritten',
    color: '#000000',
  },
  'quote:created': {
    title: 'Created',
    color: '#000000',
  },
  'quote:referral-required': {
    title: 'Referral required',
    color: '#F8D964',
  },
  'quote:in-manual-referral': {
    title: 'In Referral',
    color: '#FFBF00',
  },
  'quote:bound': {
    title: 'Quote bound',
    color: '#90EE90',
  },
  'quote:declined': {
    title: 'Customer declined',
    color: '#D22B2B',
  },
  'referral:manual-accepted': {
    title: 'Underwritten',
    color: '#000000',
  },
  'referral:automatic-declined': {
    title: 'Declined',
    color: '#D22B2B',
  },
  'referral:manual-declined': {
    title: 'Declined',
    color: '#D22B2B',
  },
  'quote:expired': {
    title: 'Expired',
    color: '#D22B2B',
  },
};

export const POLICY_STATUS_CONFIG = {
  policy_issued: {
    title: 'Issued',
    color: '#50C878',
  },
  policy_changed: {
    title: 'Changed',
    color: '#50C878',
  },
  policy_terminated: {
    title: 'Terminated',
    color: '#D22B2B',
  },
  invoice_generated: {
    title: 'Generated',
    color: '#000000',
  },
  policy_expired: {
    title: 'Expired',
    color: '#000000',
  },
};

export const CUSTOMER_STATUSES_MAP = {
  Active: 'is_active',
  NotActive: 'not_active',
};

export const CUSTOMER_STATUS_CONFIG = {
  [CUSTOMER_STATUSES_MAP.Active]: {
    title: 'Active',
    color: '#50C878',
  },
  [CUSTOMER_STATUSES_MAP.NotActive]: {
    title: 'Not active',
    color: '#000000',
  },
};

export const INVOICE_STATUS_CONFIG = {
  issued: {
    title: 'Issued',
    color: '#50C878',
  },
  draft: {
    title: 'Draft',
    color: '#000000',
  },
  unpaid: {
    title: 'Unpaid',
    color: '#D22B2B',
  },
  paid: {
    title: 'Paid',
    color: '#50C878',
  },
};

import React from 'react';
import clsx from 'clsx';
import { TextField, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ spacing }) => ({
  margin: {
    marginTop: spacing(3),
  },
  textField: {
    marginRight: spacing(0.6),
    width: '8ch',
    '& > div > *': {
      textAlign: 'center',
    },
  },
  inputsBlock: {
    textAlign: 'center',
  },
}));

export default ({ disabled, digits, updateDigits }) => {
  const classes = useStyles();
  const digitRefs = Array.from({ length: digits.length }, () =>
    React.createRef()
  );

  const updateDigit = index => {
    const { value, maxLength } = digitRefs[index].current;

    if (value.length < maxLength) return;

    if (index < digitRefs.length - 1) digitRefs[index + 1].current.focus();

    const newDigits = [...digits];

    newDigits[index] = value;

    updateDigits(newDigits);
  };

  return (
    <div className={clsx(classes.margin, classes.inputsBlock)}>
      {digitRefs.map((_, index) => (
        <TextField
          autoFocus={index === 0}
          autoComplete="off"
          disabled={disabled}
          inputRef={digitRefs[index]}
          key={`input-${index}`}
          onChange={() => updateDigit(index)}
          variant="outlined"
          type="text"
          className={classes.textField}
          inputProps={{
            maxLength: 1,
          }}
        />
      ))}
    </div>
  );
};

import * as MakeModelApi from '../makeModel';

export async function fetch(page, perPage, searchterm) {
  try {
    return await MakeModelApi.fetch(page, perPage, searchterm);
  } catch (error) {
    throw error.response.data;
  }
}

export async function getItemData(id) {
  try {
    return await MakeModelApi.getItemData(id);
  } catch (error) {
    throw error.response.data;
  }
}

export async function edit(id, data) {
  try {
    return await MakeModelApi.edit(id, data);
  } catch (error) {
    throw error.response.data;
  }
}

export async function insert(data) {
  try {
    return await MakeModelApi.insert(data);
  } catch (error) {
    throw error.response.data;
  }
}

export async function fileUpload(importFile) {
  const formData = new FormData();

  formData.append('make_model_file', importFile, importFile.name);

  try {
    return await MakeModelApi.fileUpload(formData);
  } catch (error) {
    throw error.response.data;
  }
}

import { ledger } from 'src/api/endpoint';

export function getProductFeatures(schemaName) {
  return ledger.get(
    `/sales/features-configuration/product-features/${schemaName}`
  );
}

export function getProductFeature(schemaName, featureName) {
  return ledger.get(`/sales/product-features/${schemaName}/${featureName}`);
}

export function updateProductFeature(schemaName, payload) {
  return ledger.put(`/sales/product-features/${schemaName}`, payload);
}

export function getTenantFeatures() {
  return ledger.get('/sales/features-configuration/tenant-features');
}

export function getTenantFeature(featureName) {
  return ledger.get(
    `/sales/features-configuration/tenant-features/${featureName}`
  );
}

export function updateTenantFeature(payload) {
  return ledger.put('/sales/features-configuration/tenant-features', payload);
}

export function getChatSettings() {
  return ledger.get(`/chat/settings`);
}

import React from 'react';
import { Link } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  logo: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
});

export default ({ src, href, className }) => {
  const classes = useStyles();

  if (href)
    return (
      <Link to={href}>
        <img alt="" src={src} className={clsx(classes.logo, className)} />
      </Link>
    );

  return <img alt="" src={src} className={clsx(classes.logo, className)} />;
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, TextField, Button, makeStyles } from '@material-ui/core';

import { LOGIN_PAGE } from 'src/constants';
import { Form, AuthError } from 'src/components/AuthForm';
import useSetState from 'src/hooks/useSetState';
import useAsyncDispatch from 'src/hooks/useAsyncDispatch';
import PasswordField, {
  PasswordType,
  PasswordIconControl,
  usePasswordsValidation,
} from 'src/components/PasswordField';

import { updatePasswordRequest } from './requestHelper';

const useStyles = makeStyles(({ typography, spacing }) => ({
  title: {
    fontWeight: typography.fontWeightBold,
  },
  actionButton: {
    height: '48px',
    width: '100%',
    textTransform: 'none',
    fontWeight: typography.fontWeightMedium,
    fontSize: '18',
    marginTop: spacing(3),
  },
  descriptionBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: spacing(3),
    marginBottom: spacing(3),
  },
  textField: {
    marginTop: spacing(2),
  },
}));

export default ({ value, action }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [error, setError] = React.useState(null);
  const [isLoading, asyncDispatch] = useAsyncDispatch();

  const [passwordData, setPasswordData] = useSetState({
    resetCode: '',
    newPassword: '',
    confirmationPassword: '',
  });

  const [isMasked, setIsMasked] = React.useState(true);
  const [passwordsState, validatePasswords] = usePasswordsValidation();

  const handleChange = ({ target }) => {
    const { name, value } = target;

    setPasswordData({
      [name]: value?.trim(),
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setError(null);

    const isWIthErrors = validatePasswords({
      [PasswordType.New]: passwordData.newPassword,
      [PasswordType.Confirmation]: passwordData.confirmationPassword,
    });

    if (isWIthErrors) return;

    asyncDispatch(async () => {
      try {
        await updatePasswordRequest(value.username, passwordData);

        history.push(LOGIN_PAGE);
      } catch (error) {
        setError(error);
        console.error(error);
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Typography component="h1" variant="h3" className={classes.title}>
        {t('Reset password')}
      </Typography>
      <div className={classes.descriptionBlock}>
        <Typography component="h3" variant="h5">
          {t('You can reset your password using this form')}
        </Typography>
        <PasswordIconControl value={isMasked} onChange={setIsMasked} />
      </div>
      <AuthError error={error} />
      <TextField
        required
        disabled={isLoading}
        className={classes.textField}
        label={t('Password reset code')}
        name="resetCode"
        type="text"
        variant="outlined"
        fullWidth
        autoComplete="off"
        value={passwordData.resetCode}
        onChange={handleChange}
      />
      <PasswordField
        required
        disabled={isLoading}
        label={t('New password')}
        value={passwordData.newPassword}
        name="newPassword"
        onChange={handleChange}
        isMasked={isMasked}
        autoComplete="new-password"
        error={!!passwordsState.errors[PasswordType.New]}
        helperText={t(passwordsState.errors[PasswordType.New])}
      />
      <PasswordField
        required
        disabled={isLoading}
        label={t('Confirm new password')}
        value={passwordData.confirmationPassword}
        name="confirmationPassword"
        onChange={handleChange}
        isMasked={isMasked}
        autoComplete="new-password"
        error={!!passwordsState.errors[PasswordType.Confirmation]}
        helperText={t(passwordsState.errors[PasswordType.Confirmation])}
      />
      <Button
        className={classes.actionButton}
        disabled={isLoading}
        variant="contained"
        color="primary"
        type="submit"
      >
        {t('Update password')}
      </Button>
      <Button
        className={classes.actionButton}
        disabled={isLoading}
        variant="contained"
        color="primary"
        onClick={action.goToResetPassword}
      >
        {t('Resend code')}
      </Button>
    </Form>
  );
};

import { INTERCOM_SECRET_KEY } from 'src/config/environment';

export default async email => {
  const enc = new TextEncoder('utf-8');
  const algorithm = { name: 'HMAC', hash: 'SHA-256' };

  const key = await crypto.subtle.importKey(
    'raw',
    enc.encode(INTERCOM_SECRET_KEY),
    algorithm,
    false,
    ['sign', 'verify']
  );
  const signature = await crypto.subtle.sign(
    algorithm.name,
    key,
    enc.encode(email)
  );

  const hashArray = new Uint8Array(signature);

  return Array.prototype.map
    .call(hashArray, h => h.toString(16).padStart(2, '0'))
    .join('');
};

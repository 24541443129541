import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectUserState, selectIsLoggedIn } from 'src/redux/modules/auth';
import AuthForm from 'src/components/AuthForm';
import { HOME_PAGE } from 'src/constants';
import { hasRedirectFlag } from 'src/helpers/loginRedirect';

import Steps from './Steps';

export default () => {
  const history = useHistory();
  const user = useSelector(selectUserState);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  React.useEffect(() => {
    if (!isLoggedIn) return;

    const hasRedirectBack = hasRedirectFlag();
    const isSameUser = !user.prevHash || user.prevHash === user.hash;

    if (isSameUser && hasRedirectBack) history.goBack();
    else history.push(HOME_PAGE);
  }, [user]);

  return (
    <AuthForm customForm>
      <Steps />
    </AuthForm>
  );
};

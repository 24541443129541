import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, TextField, Button, makeStyles } from '@material-ui/core';

import { Form, AuthError } from 'src/components/AuthForm';
import useAsyncDispatch from 'src/hooks/useAsyncDispatch';

import { resetPasswordRequest } from './requestHelper';

const useStyles = makeStyles(({ typography }) => ({
  title: {
    fontWeight: 'bold',
  },
  actionButton: {
    height: '48px',
    width: '100%',
    textTransform: 'none',
    fontWeight: typography.fontWeightMedium,
    fontSize: '18px',
  },
}));

export default ({ value, action }) => {
  const classes = useStyles();
  const formRef = React.useRef();
  const { t } = useTranslation();

  const [error, setError] = React.useState(null);
  const [isLoading, asyncDispatch] = useAsyncDispatch();

  const handleSubmit = event => {
    event.preventDefault();
    setError(null);

    asyncDispatch(async () => {
      try {
        await resetPasswordRequest(value.username);

        action.goToUpdatePassword();
      } catch (error) {
        setError(error);
        console.error(error);
      }
    });
  };

  const handleUpdatePasswordRedirect = () => {
    const { current: form } = formRef;
    const isFormValid = form.reportValidity();

    if (isFormValid) action.goToUpdatePassword();
  };

  const handleChange = ({ target }) => {
    const { value } = target;

    action.setUsername(value ? value : null);
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Typography component="h1" variant="h3" className={classes.title}>
        {t('Forgot password')}
      </Typography>
      <Typography component="h3" variant="h5">
        {t(
          'Enter the email address you use to log in to your account. We will e-mail you the password reset code'
        )}
      </Typography>
      <AuthError error={error} />
      <TextField
        required
        disabled={isLoading}
        label={t('Email')}
        type="email"
        name="email"
        variant="outlined"
        fullWidth
        value={value.username ?? ''}
        onChange={handleChange}
      />
      <Button
        className={classes.actionButton}
        disabled={isLoading}
        variant="contained"
        color="primary"
        type="submit"
      >
        {t('Reset password')}
      </Button>
      <Button
        className={classes.actionButton}
        disabled={isLoading}
        variant="contained"
        color="primary"
        onClick={handleUpdatePasswordRedirect}
      >
        {t('I already have a reset code')}
      </Button>
    </Form>
  );
};

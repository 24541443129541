import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog as MuiDialog,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

import { DefaultActions } from './DefaultActions';

const DIALOG_INDEX = 1000;

const useStyles = makeStyles(({ spacing }) => ({
  dialog: {
    '& ~ *': {
      zIndex: DIALOG_INDEX,
    },
  },
  title: {
    padding: spacing(3),
    '& > *': {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '24px',
    },
  },
  actions: {
    padding: spacing(3),
  },
  closeIcon: {
    position: 'absolute',
    top: spacing(1.5),
    right: spacing(1.5),
    color: '#121212',
  },
}));

export default ({
  title,
  children,
  actions,
  onClose,
  closeTitle,
  onConfirm,
  confirmTitle,
  open: openProp,
  maxWidth = 'sm',
  titleProps = {},
  classes = {},
  disableBackdropClick,
  hideActions,
  disableInternalOpenLogic,
  ...props
}) => {
  const innerClasses = useStyles();
  const [open, setOpen] = useState(openProp);
  const { t } = useTranslation();
  const handleConfirm = () => {
    onConfirm?.();
    if (!disableInternalOpenLogic) setOpen(false);
  };
  const handleClose = (_, reason) => {
    if (disableBackdropClick && reason === 'backdropClick') return;

    onClose?.();
    if (!disableInternalOpenLogic) setOpen(false);
  };

  useEffect(() => setOpen(openProp), [openProp]);

  return (
    <MuiDialog
      fullWidth
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={innerClasses.dialog}
      style={{ zIndex: DIALOG_INDEX }}
      classes={{
        paper: classes.paper,
      }}
      {...props}
    >
      <IconButton
        aria-label="close"
        className={innerClasses.closeIcon}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      {title && (
        <DialogTitle
          className={clsx(innerClasses.title, classes.title)}
          {...titleProps}
          id="alert-dialog-title"
        >
          {t(title)}
        </DialogTitle>
      )}
      {children && (
        <DialogContent className={classes.content}>{children}</DialogContent>
      )}
      {!hideActions && (
        <DialogActions className={clsx(innerClasses.actions, classes.actions)}>
          {actions || (
            <DefaultActions
              confirmTitle={confirmTitle}
              closeTitle={closeTitle}
              onConfirm={handleConfirm}
              onClose={handleClose}
            />
          )}
        </DialogActions>
      )}
    </MuiDialog>
  );
};

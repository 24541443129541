import Subscriber from 'src/lib/subscriber';

class NotifyService {
  #subscriber = new Subscriber();

  success(message, options = {}) {
    const variant = 'success';

    this.#subscriber.publish({
      message,
      options,
      variant,
    });
  }

  warning(message, options = {}) {
    const variant = 'warning';

    this.#subscriber.publish({
      message,
      options,
      variant,
    });
  }

  error(message, options = {}) {
    const variant = 'error';

    this.#subscriber.publish({
      message,
      options,
      variant,
    });
  }

  subscribe(callback) {
    return this.#subscriber.subscribe(callback);
  }
}

export default new NotifyService();

import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';

import { LOOKUP_LOCAL_STORAGE_KEY, LANGUAGES_MAP } from 'src/constants/common';

const requireLocale = () => {
  const localeKey =
    localStorage.getItem(LOOKUP_LOCAL_STORAGE_KEY) || LANGUAGES_MAP.English;

  try {
    return require(`dayjs/locale/${localeKey}`);
  } catch {
    return require(`dayjs/locale/${LANGUAGES_MAP.English}`);
  }
};

export default ({ children }) => {
  const locale = requireLocale();

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils} locale={locale}>
      {children}
    </MuiPickersUtilsProvider>
  );
};

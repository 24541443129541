import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase, Menu, MenuItem, makeStyles } from '@material-ui/core';

import { getSchemasListByType } from 'src/api/interfaces/schema';
import { useGoToClaimNew } from 'src/effects/useNavigation';
import stopPropagation from 'src/helpers/stopPropagation';

const SCHEMA_TYPE = 'claim-fnol-internal';

const useStyles = makeStyles(({ spacing }) => ({
  popover: {
    minWidth: 260,
    padding: spacing(2),
  },
  menuItem: {
    padding: '14px 20px',
  },
}));

export default React.forwardRef(({ className, children }, ref) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const goToNewClaim = useGoToClaimNew();

  const [schemas, setSchemas] = React.useState([]);
  const [anchorElement, setAnchorElement] = React.useState(null);

  const openSchema = (event, schema) => {
    stopPropagation(event);
    setAnchorElement(null);
    goToNewClaim(schema);
  };

  const hideMenu = event => {
    stopPropagation(event);
    setAnchorElement(null);
  };

  const showMenu = event => {
    const { currentTarget } = event;

    stopPropagation(event);
    setAnchorElement(currentTarget);
  };

  React.useEffect(() => {
    (async () => {
      const schemas = await getSchemasListByType(SCHEMA_TYPE);

      setSchemas(schemas);
    })();
  }, []);

  return (
    <ButtonBase ref={ref} className={className} onClick={showMenu}>
      {children}
      {schemas && anchorElement && (
        <Menu
          anchorEl={anchorElement}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          transformOrigin={{ horizontal: 0, vertical: 'top' }}
          PopoverClasses={{ paper: classes.popover }}
          open={!!anchorElement}
          onClose={hideMenu}
        >
          {schemas.map((schema, index) => (
            <MenuItem
              key={`claim-schema-item-${index}`}
              value={schema}
              className={classes.menuItem}
              onClick={event => openSchema(event, schema)}
            >
              {t(schema.title)}
            </MenuItem>
          ))}
        </Menu>
      )}
    </ButtonBase>
  );
});

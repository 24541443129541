import { ledger, asyncLedger } from 'src/api/endpoint';

export function getProducts() {
  return ledger.get('/policies/bdx-import/products');
}

export function getLog(page, limit) {
  return ledger.get('/policies/bdx-import/log', { page, limit });
}

export function bdxImport(schema, data, config) {
  return asyncLedger.post(`/policies/bdx-import/${schema}`, data, config);
}

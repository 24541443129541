const hidden = {
  display: 'none !important',
};

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const upperCaseFirstLetter = {
  display: 'inline-block',
  textTransform: 'lowercase',
  '&::first-letter': {
    textTransform: 'uppercase',
  },
};

const listToStyles = (attribute, list) => {
  const styles = list
    .filter(({ style }) => !!style)
    .map(({ key, style }) => [`& [${attribute}="${key}"]`, style]);

  return Object.fromEntries(styles);
};

export default {
  hidden,
  flexCenter,
  upperCaseFirstLetter,
  listToStyles,
};

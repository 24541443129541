import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';

import notify from 'src/service/NotifyService';

const useStyles = makeStyles({
  closeIcon: {
    cursor: 'pointer',
  },
});

const isJsonBlob = data =>
  data instanceof Blob && data.type === 'application/json';

const getErrorData = async (data = {}) =>
  isJsonBlob(data) ? data.text().then(JSON.parse) : data;

export default () => {
  const classes = useStyles();

  const action = close => (
    <CloseIcon onClick={close} className={classes.closeIcon} />
  );

  return async error => {
    const errorData = await getErrorData(error.response?.data);

    let errorMessage = errorData.message || error.toString();

    if (errorData.data?.message) errorMessage = errorData.data.message;

    notify.error(errorMessage, { action, persist: false });
  };
};

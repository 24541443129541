import { lighten } from '@material-ui/core/styles/colorManipulator';

import palette from '../palette';

export const PAGINATION_BASE_COLOR = lighten(palette.text.primary, 0.4);

export default {
  spacer: {
    display: 'none',
  },
  toolbar: {
    paddingLeft: 16,
  },
  caption: {
    color: PAGINATION_BASE_COLOR,
  },
  actions: {
    '& > *': {
      color: PAGINATION_BASE_COLOR,
    },
    marginLeft: 10,
  },
  input: {
    color: PAGINATION_BASE_COLOR,
    marginRight: 'auto',
  },
  selectIcon: {
    color: PAGINATION_BASE_COLOR,
  },
};

import tenantTagService from 'src/service/TenantTagService';
import { LOOKUP_LOCAL_STORAGE_KEY } from 'src/constants/common';

export default Target =>
  class extends Target {
    getConfig(method, url, data, config) {
      const requestConfig = super.getConfig(method, url, data, config);

      const headers = {
        ...requestConfig.headers,
        'X-TENANT-ID': tenantTagService.tenantTag,
        'Accept-Language': localStorage.getItem(LOOKUP_LOCAL_STORAGE_KEY),
      };

      return {
        ...requestConfig,
        headers,
      };
    }
  };

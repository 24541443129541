import PasswordType from './Type';
import ErrorMessage from './Message';
import Validator from './Validator';

export default [
  {
    target: PasswordType.New,
    message: ErrorMessage.RequiredField,
    validate: passwords => Validator.isEmpty(passwords[PasswordType.New]),
  },
  {
    target: PasswordType.New,
    message: ErrorMessage.WeakPassword,
    validate: passwords => Validator.isWeak(passwords[PasswordType.New]),
  },
  {
    target: PasswordType.Confirmation,
    message: ErrorMessage.RequiredField,
    validate: passwords =>
      Validator.isEmpty(passwords[PasswordType.Confirmation]),
  },
  {
    target: PasswordType.Confirmation,
    message: ErrorMessage.DifferentFields,
    validate: passwords => Validator.isDifferent(...Object.values(passwords)),
  },
];

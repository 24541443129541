import lodash from 'lodash';

export const convertKeysToCamelCase = object =>
  lodash.transform(object, (accumulator, value, key, target) => {
    const formattedKey = lodash.isArray(target) ? key : lodash.camelCase(key);

    accumulator[formattedKey] = lodash.isObject(value)
      ? convertKeysToCamelCase(value)
      : value;
  });

export const convertKeysToSnakeCase = object =>
  lodash.transform(object, (accumulator, value, key, target) => {
    const formattedKey = lodash.isArray(target) ? key : lodash.snakeCase(key);

    accumulator[formattedKey] = lodash.isObject(value)
      ? convertKeysToSnakeCase(value)
      : value;
  });

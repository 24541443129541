import React from 'react';

import useSetState from 'src/hooks/useSetState';

import SignIn from './SignIn';
import MfaLogin from './MfaLogin';
import FirstLogin from './FirstLogin';
import MfaSetup from './MfaSetup';

const Step = {
  SignIn: 'signIn',
  MfaLogin: 'loginWithMfa',
  FirstLogin: 'firstLogin',
  MfaSetup: 'mfaSetup',
};

const stepMap = new Map([
  [Step.SignIn, SignIn],
  [Step.MfaLogin, MfaLogin],
  [Step.FirstLogin, FirstLogin],
  [Step.MfaSetup, MfaSetup],
]);

export default () => {
  const [activeStep, setActiveStep] = React.useState(Step.SignIn);

  const goToSignIn = () => setActiveStep(Step.SignIn);
  const goToMfaLogin = () => setActiveStep(Step.MfaLogin);
  const goToFirstLogin = () => setActiveStep(Step.FirstLogin);
  const goToMfaSetup = () => setActiveStep(Step.MfaSetup);

  const [loginData, setLoginData] = React.useState(null);
  const [credentials, setCredentials] = useSetState({
    username: '',
    password: '',
  });

  const action = {
    goToSignIn,
    goToMfaLogin,
    goToFirstLogin,
    goToMfaSetup,
    setCredentials,
    setLoginData,
  };

  const value = {
    credentials,
    loginData,
  };

  const ActiveStep = stepMap.get(activeStep);
  const props = { value, action };

  return <ActiveStep {...props} />;
};

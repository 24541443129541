import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  actionBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  margin: {
    marginTop: theme.spacing(3),
  },
}));

function MfaSetUpForm({ code, onSubmit, onChange }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid component="form" onSubmit={onSubmit} item>
      <Typography gutterBottom variant="h5" component="h2">
        {t('Enter the six-digit code from the application.')}
      </Typography>
      <Typography variant="h6" color="textSecondary" component="p">
        {t(
          'After scanning the QR code, the application will display a six-digit code which you can enter below.'
        )}
      </Typography>
      <TextField
        type="text"
        fullWidth
        className={classes.margin}
        name="secureCode"
        required
        variant="outlined"
        inputProps={{
          maxLength: 6,
        }}
        value={code}
        onChange={onChange}
      />
      <div className={classes.actionBlock}>
        <Button variant="contained" color="primary" type="submit">
          {t('enable')}
        </Button>
      </div>
    </Grid>
  );
}

export default MfaSetUpForm;

import { API_URL_UMS } from 'src/config/environment';
import { Request, Endpoint } from 'src/lib/request';
import {
  pipe,
  tenantRequestMixin,
  authRequestMixin,
  errorRequestMixin,
  tokenRequestMixin,
} from 'src/api/mixin';

const UmsRequest = pipe(Request)
  .chain(tenantRequestMixin)
  .chain(authRequestMixin)
  .chain(errorRequestMixin)
  .chain(tokenRequestMixin)
  .value();

const request = new UmsRequest({ host: API_URL_UMS });
const endpoint = new Endpoint(request);

export default endpoint;

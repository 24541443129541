import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(theme => ({
  eye: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}));

const PasswordIconControl = ({ value = false, onChange }) => {
  const classes = useStyles();

  const onClick = () => onChange?.(!value);

  const passwordIcon = value ? <Visibility /> : <VisibilityOff />;

  return (
    <InputAdornment className={classes.eye} onClick={onClick} position="end">
      {passwordIcon}
    </InputAdornment>
  );
};

PasswordIconControl.propTypes = {
  onChange: PropTypes.func,
};

export default PasswordIconControl;

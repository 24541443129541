import React, { useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';

import Loading from 'src/components/Loading';
import AccessDenied from 'src/containers/Errors/403';
import accessService from 'src/service/AccessService';
import chatService from 'src/service/ChatService';
import initializationService from 'src/service/InitializationService';
import useUserInfo from 'src/effects/useUserInfo';

import Dashboard from './Dashboard';

const getProtectedRoutes = (routes, accessActions) =>
  routes.map(route => {
    const { resourceType, component: RouteComponent } = route;
    const isRouteDenied =
      !!resourceType && !accessActions.includes(resourceType);
    const component = isRouteDenied ? AccessDenied : RouteComponent;

    return {
      ...route,
      component,
    };
  });

export default ({ route: { routes } }) => {
  const history = useHistory();
  const userInfo = useUserInfo();
  const [isInitialized, setInitialized] = useState(false);

  useEffect(() => {
    initializationService.private
      .initialize()
      .then(() => {
        chatService.chatInstance?.boot(userInfo);

        setInitialized(true);
      })
      .catch(() => history.push('/errors/error-403'));

    return () => initializationService.private.dispose();
  }, []);

  if (!isInitialized) return <Loading />;

  const { accessActions } = accessService;
  const protectedRoutes = getProtectedRoutes(routes, accessActions);

  const renderProtectedRoutes = props => (
    <Dashboard {...props}>{renderRoutes(protectedRoutes)}</Dashboard>
  );

  return <Route render={renderProtectedRoutes} />;
};

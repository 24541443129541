import React from 'react';

import AuthForm from 'src/components/AuthForm';

import Steps from './Steps';

export default () => (
  <AuthForm customForm>
    <Steps />
  </AuthForm>
);

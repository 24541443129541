export default class Cancelable {
  #request = null;

  constructor(request) {
    this.#request = request;
  }

  get(url, params, config) {
    return this.#request.cancelable('GET', url, params, config);
  }

  post(url, params, config) {
    return this.#request.cancelable('POST', url, params, config);
  }

  put(url, params, config) {
    return this.#request.cancelable('PUT', url, params, config);
  }

  patch(url, params, config) {
    return this.#request.cancelable('PATCH', url, params, config);
  }

  delete(url, params, config) {
    return this.#request.cancelable('DELETE', url, params, config);
  }
}

import { useDispatch } from 'react-redux';

import useSafeState from './useSafeState';

export default function useAsyncDispatch() {
  const [isLoading, setIsLoading] = useSafeState(false);
  const dispatch = useDispatch();

  const asyncDispatch = async action => {
    setIsLoading(true);
    let data;

    try {
      data = await dispatch(action);
    } finally {
      setIsLoading(false);
    }

    return data;
  };

  return [isLoading, asyncDispatch];
}

import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';

import reducers from './modules';

const LOCAL_STORAGE_KEY = 'store';

export function configureStore(initialState) {
  const enhancers = [applyMiddleware(thunk)];

  if (typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'function')
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());

  const savedStore =
    localStorage.getItem(LOCAL_STORAGE_KEY) &&
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '');

  if (savedStore) initialState = savedStore;

  const store = createStore(reducers, initialState, compose(...enhancers));

  store.asyncReducers = {};

  return store;
}

export const store = configureStore();

store.subscribe(() => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(store.getState()));
});

export default store;

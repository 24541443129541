import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { LOGIN_PAGE } from 'src/constants';
import { getRedirectPath } from 'src/helpers/loginRedirect';
import { selectIsLoggedIn } from 'src/redux/modules/auth';
import { isSessionTimeout } from 'src/components/UserTracker/utils';

import Protected from './Protected';

const redirectToLogin = ({ location }) => (
  <Redirect
    to={{
      pathname: LOGIN_PAGE,
      state: { from: location },
      search: getRedirectPath(location.pathname),
    }}
    push
  />
);

export default ({ route }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  if (!isLoggedIn || isSessionTimeout())
    return <Route render={redirectToLogin} />;

  return <Protected route={route} />;
};

import { API_URL_IDENTIFIER } from 'src/config/environment';
import { Request, Endpoint } from 'src/lib/request';
import { pipe, errorRequestMixin, authRequestMixin } from 'src/api/mixin';

const IdentifierRequest = pipe(Request)
  .chain(authRequestMixin)
  .chain(errorRequestMixin)
  .value();

const request = new IdentifierRequest({ host: API_URL_IDENTIFIER });
const endpoint = new Endpoint(request);

export default endpoint;

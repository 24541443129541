import React from 'react';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core';

import NotificationConsumer from './NotificationConsumer';

const useStyle = makeStyles({
  variantError: {
    flexDirection: 'row',
  },
});

export default React.memo(({ children }) => {
  const classes = useStyle();

  return (
    <SnackbarProvider classes={{ variantError: classes.variantError }}>
      <NotificationConsumer />
      {children}
    </SnackbarProvider>
  );
});

import { API_URL_SITES_SERVICE } from 'src/config/environment';
import { Request, Endpoint } from 'src/lib/request';
import { pipe, tenantRequestMixin, errorRequestMixin } from 'src/api/mixin';

const SitesRequest = pipe(Request)
  .chain(tenantRequestMixin)
  .chain(errorRequestMixin)
  .value();

const request = new SitesRequest({ host: API_URL_SITES_SERVICE });
const endpoint = new Endpoint(request);

export default endpoint;

import { lighten } from '@material-ui/core/styles/colorManipulator';

import palette from '../palette';
import typography from '../typography';

export default {
  root: {
    ...typography.body1,
    padding: '10px 16px',
    borderBottom: `1px solid ${palette.divider}`,
  },
  head: {
    fontWeight: 700,
    fontSize: 10,
    color: palette.label,
    letterSpacing: 1.5,
    textTransform: 'uppercase',
    lineHeight: '12px',
  },
  body: {
    color: lighten(palette.text.primary, 0.2),
  },
};

export default class StatusPromise extends Promise {
  #isPending = true;
  #isRejected = false;
  #isFulfilled = false;

  static resolve(payload) {
    return new StatusPromise((callback, _) =>
      Promise.resolve().then(() => callback(payload))
    );
  }

  static reject(payload) {
    return new StatusPromise((_, callback) =>
      Promise.reject().then(() => callback(payload))
    );
  }

  constructor(callback) {
    super((resolve, reject) => {
      const customResolve = (...args) => {
        this.#isPending = false;
        this.#isFulfilled = true;

        return resolve(...args);
      };

      const customReject = (...args) => {
        this.#isPending = false;
        this.#isRejected = true;

        return reject(...args);
      };

      return callback(customResolve, customReject);
    });
  }

  get isPending() {
    return this.#isPending;
  }

  get isRejected() {
    return this.#isRejected;
  }

  get isFulfilled() {
    return this.#isFulfilled;
  }
}

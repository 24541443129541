import { lazy } from 'react';

export default [
  {
    path: '/errors/error-401',
    component: lazy(() => import('src/containers/Errors/401')),
  },
  {
    path: '/errors/error-403',
    component: lazy(() => import('src/containers/Errors/403')),
  },
  {
    path: '/errors/error-404',
    component: lazy(() => import('src/containers/Errors/404')),
  },
  {
    path: '/errors/error-500',
    component: lazy(() => import('src/containers/Errors/500')),
  },
];

import React from 'react';

import Subscriber from 'src/lib/subscriber';

import * as scheduler from './scheduler';
import { initTrackTime, trackTime, checkTime } from './utils';
import {
  TRACK_EVENTS,
  TRACK_STORAGE_NAME,
  ACTIVITY_TIME_DELAY,
} from './constants';

export default () => {
  const [isActive, setActivity] = React.useState(true);
  const [subscriber] = React.useState(() => new Subscriber());

  const [trackPlanner] = React.useState(() =>
    scheduler.throttle(trackTime, ACTIVITY_TIME_DELAY)
  );
  const [checkPlanner] = React.useState(() =>
    scheduler.timeout(checkTime, ACTIVITY_TIME_DELAY)
  );

  const activityEventListener = React.useCallback(
    () => trackPlanner.start(subscriber),
    []
  );

  const subscribeActivity = () =>
    TRACK_EVENTS.forEach(eventName =>
      document.addEventListener(eventName, activityEventListener, {
        capture: true,
      })
    );

  const unsubscribeActivity = () =>
    TRACK_EVENTS.forEach(eventName =>
      document.removeEventListener(eventName, activityEventListener, {
        capture: true,
      })
    );

  const startTracking = () => {
    initTrackTime();
    subscribeActivity();
    checkPlanner.start(subscriber);
  };

  const stopTracking = () => {
    unsubscribeActivity();
    checkPlanner.stop();
    trackPlanner.stop();
  };

  const clearTracking = () => {
    localStorage.removeItem(TRACK_STORAGE_NAME);
  };

  React.useEffect(() => {
    const checkVisibility = () =>
      setActivity(document.visibilityState === 'visible');

    document.addEventListener('visibilitychange', checkVisibility);

    return () =>
      document.removeEventListener('visibilitychange', checkVisibility);
  }, []);

  return {
    isActive,
    subscriber,
    stopTracking,
    clearTracking,
    startTracking,
  };
};

export class Initializer {
  initialize() {
    throw new Error('Initialize method not implemented exception');
  }

  dispose() {
    throw new Error('Dispose method not implemented exception');
  }
}

export default class InitializeManager {
  #services = new Set();
  #initialized = false;

  register = service => {
    this.#services.add(service);
  };

  initialize = async () => {
    if (this.#initialized) return;

    this.#initialized = true;

    for (const service of this.#services) await service.initialize();
  };

  dispose() {
    this.#initialized = false;

    for (const service of this.#services) service.dispose();
  }
}

import Subscriber from 'src/lib/subscriber';

class ApiErrorService {
  #subscriber = new Subscriber();

  push(error) {
    this.#subscriber.publish(error);
  }

  subscribe(callback) {
    return this.#subscriber.subscribe(callback);
  }
}

export default new ApiErrorService();

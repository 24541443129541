import * as BrokerApi from 'src/api/broker';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants';

/** initial state */
const initialState = {
  list: null,
  brokersList: null,
  page: 1,
  rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  totalCount: 0,
};

/** selectors */
export const selectBrokersList = state => state.brokers.list;
export const selectOnlyBrokersShortNames = state =>
  state.brokers.brokersList || [];
export const selectBrokersPaginationData = state => ({
  page: state.brokers.page,
  rowsPerPage: state.brokers.rowsPerPage,
  totalCount: state.brokers.totalCount,
});

/** action types */
const prefix = 'brokers';
const ActionTypes = {
  FETCH_LIST: `${prefix}/FETCH_LIST`,
  FETCH_LIST_SUCCESS: `${prefix}/FETCH_LIST_SUCCESS`,
  FETCH_LIST_FAIL: `${prefix}/FETCH_LIST_FAIL`,
  FETCH_BROKERS_LIST: `${prefix}/FETCH_BROKERS_LIST`,
  FETCH_BROKERS_LIST_SUCCESS: `${prefix}/FETCH_BROKERS_LIST_SUCCESS`,
  FETCH_BROKERS_LIST_FAIL: `${prefix}/FETCH_BROKERS_LIST_FAIL`,
};

/** actions */
export function search(
  searchTerm = '',
  page = 1,
  rowsPerPage = DEFAULT_ROWS_PER_PAGE
) {
  return async dispatch => {
    try {
      dispatch({ type: ActionTypes.FETCH_LIST });
      const data = await BrokerApi.search(searchTerm, page, rowsPerPage);

      dispatch({ type: ActionTypes.FETCH_LIST_SUCCESS, payload: data });
    } catch (e) {
      dispatch({
        type: ActionTypes.FETCH_LIST_FAIL,
        payload: e.response && e.response.data.message,
      });
    }
  };
}

export function fetchBrokersList() {
  return async dispatch => {
    try {
      dispatch({ type: ActionTypes.FETCH_BROKERS_LIST });

      const brokers = await BrokerApi.getBrokerShortNames();

      dispatch({
        type: ActionTypes.FETCH_BROKERS_LIST_SUCCESS,
        payload: brokers,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.FETCH_BROKERS_LIST_FAIL,
        payload: error.response && error.response.data.message,
      });
    }
  };
}

/** default reducer */
export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.FETCH_LIST:
      return {
        ...state,
        list: null,
      };
    case ActionTypes.FETCH_LIST_SUCCESS:
      return {
        ...state,
        list: payload.data,
        page: payload.current_page,
        rowsPerPage: payload.per_page,
        totalCount: payload.total,
      };
    case ActionTypes.FETCH_LIST_FAIL:
      return {
        ...state,
        list: null,
        page: 1,
        rowsPerPage: 0,
        totalCount: 0,
      };
    case ActionTypes.FETCH_BROKERS_LIST_SUCCESS:
      return {
        ...state,
        brokersList: payload,
      };
    case ActionTypes.FETCH_BROKERS_LIST_FAIL:
      return {
        ...state,
        brokersList: null,
      };
    default:
      return state;
  }
}

import React from 'react';

import { Config } from '../validation';

const validatePasswords = passwords =>
  Config.filter(({ validate }) => validate(passwords))
    .filter(
      ({ target: prevTarget }, prevIndex, validators) =>
        validators.findIndex(
          ({ target: nextTarget }) => prevTarget === nextTarget
        ) === prevIndex
    )
    .reduce(
      (accumulator, { target, message }) => ({
        ...accumulator,
        [target]: message,
      }),
      {}
    );

export default () => {
  const [errors, setErrors] = React.useState({});
  const [withErrors, setErrorsStatus] = React.useState(false);

  const validate = passwords => {
    const errors = validatePasswords(passwords);
    const isWithErrors = !!Object.keys(errors).length;

    setErrors(errors);
    setErrorsStatus(isWithErrors);

    return isWithErrors;
  };

  return [{ errors, withErrors }, validate];
};

import { useRef, useEffect, useState, useCallback } from 'react';

export default function useSafeState(value) {
  const mounted = useRef(false);
  const [state, unsafeSetState] = useState(value);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const setState = useCallback(value => {
    if (mounted.current) unsafeSetState(value);
  }, []);

  return [state, setState];
}

export default class Subscriber {
  #subscribers = new Set();

  subscribe(subscriber) {
    this.#subscribers.add(subscriber);

    return () => this.unsubscribe(subscriber);
  }

  unsubscribe(subscriber) {
    this.#subscribers.delete(subscriber);
  }

  publish(...args) {
    for (const subscriber of this.#subscribers) subscriber(...args);
  }

  dispose() {
    this.#subscribers.clear();
  }
}

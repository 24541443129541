import React, { Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { LinearProgress } from '@material-ui/core';

import useLayoutStyles from '../useLayoutStyles';

export default ({ route }) => {
  const location = useLocation();
  const classes = useLayoutStyles(location);

  return (
    <div className={classes.layout}>
      <div className={classes.layoutContainer}>
        <Suspense fallback={<LinearProgress />}>
          {renderRoutes(route.routes)}
        </Suspense>
      </div>
    </div>
  );
};

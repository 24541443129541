import React from 'react';
import { makeStyles } from '@material-ui/core';

import LoginLinkLogo from './LinkLogo';

const useStyles = makeStyles({
  logoContainer: {
    maxWidth: '100%',
    minHeight: '30px',
    boxSizing: 'content-box',
    display: 'flex',
    alignItems: 'center',
  },
});

export default ({ src }) => {
  const classes = useStyles();

  return (
    <div className={classes.logoContainer}>
      {!!src && <LoginLinkLogo src={src} />}
    </div>
  );
};

import { makeStyles } from '@material-ui/core';

const getBackgroundColor = ({ icon }) =>
  icon ? 'currentColor' : 'transparent';
const getIconMask = ({ icon }) =>
  icon ? `url("${icon}") center/contain no-repeat` : 'unset';

const useStyles = makeStyles({
  iconMask: {
    backgroundColor: getBackgroundColor,
    '-webkit-mask': getIconMask,
    mask: getIconMask,
  },
});

export default icon => useStyles({ icon });

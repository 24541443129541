import React from 'react';
import clsx from 'clsx';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ spacing, resources }) => ({
  authForm: {
    '& > [class*="FormControl"]': {
      display: 'flex',
    },
    '& > button': {
      display: 'block',
      width: '100%',
      backgroundColor: resources.page.login.authForm.button.backgroundColor,
      '&:hover': {
        backgroundColor:
          resources.page.login.authForm.button.hover?.backgroundColor ||
          resources.page.login.authForm.button.backgroundColor,
      },
    },
    '& > *': {
      margin: spacing(3, 0),
    },
  },
}));

export default React.forwardRef(({ onSubmit, children, className }, ref) => {
  const classes = useStyles();

  return (
    <Grid
      ref={ref}
      component="form"
      onSubmit={onSubmit}
      className={clsx(classes.authForm, className)}
    >
      {children}
    </Grid>
  );
});

import jss from 'jss';
import jssGlobal from 'jss-plugin-global';
import jssNested from 'jss-plugin-nested';
import jssCamelCase from 'jss-plugin-camel-case';
import jssRuleValueFunction from 'jss-plugin-rule-value-function';
import jssVendorPrefixer from 'jss-plugin-vendor-prefixer';
import jssPluginSyntaxDefaultUnit from 'jss-plugin-default-unit';

jss
  .use(jssGlobal())
  .use(jssNested())
  .use(jssCamelCase())
  .use(jssRuleValueFunction())
  .use(jssVendorPrefixer())
  .use(jssPluginSyntaxDefaultUnit());

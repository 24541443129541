import lodash from 'lodash';

import { Initializer } from 'src/lib/initializeManager';
import AccessActions from 'src/accessActions';
import { getChatSettings } from 'src/api/interfaces/featuresLedger';

import accessService from './AccessService';
import IntercomService from './IntercomService';

const CHAT_SERVICE = {
  intercom: IntercomService,
};

const REQUIRED_SETTINGS_PATH = {
  intercom: 'config.app_id',
};

class ChatService extends Initializer {
  #chatSettings = null;
  #chatInstance = null;

  get chatSettings() {
    return this.#chatSettings;
  }

  get chatInstance() {
    return this.#chatInstance;
  }

  async initialize() {
    const { accessActions } = accessService;

    if (accessActions.includes(AccessActions.ChatSettings)) {
      const settings = await getChatSettings();

      this.#chatSettings = settings;

      const { type, enabled } = settings;

      if (!enabled) return;

      if (
        CHAT_SERVICE[type] &&
        lodash.get(settings, REQUIRED_SETTINGS_PATH[type])
      )
        this.#chatInstance = new CHAT_SERVICE[type](settings);
    }
  }

  dispose() {
    this.#chatInstance?.dispose();
    this.#chatSettings = null;
    this.#chatInstance = null;
  }
}

export default new ChatService();

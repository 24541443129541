import i18n from 'i18next';
import Backend from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import {
  IS_DEV_MODE,
  I18N_PROJECT_ID,
  I18N_API_KEY,
  I18N_LNG,
} from 'src/config/environment';
import { LOAD_PATH } from 'src/config/locize';
import { LOOKUP_LOCAL_STORAGE_KEY, LANGUAGES_MAP } from 'src/constants/common';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: LANGUAGES_MAP.English,
    lng:
      localStorage.getItem(LOOKUP_LOCAL_STORAGE_KEY) || LANGUAGES_MAP.English,
    detection: {
      lookupLocalStorage: LOOKUP_LOCAL_STORAGE_KEY,
    },
    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false,
    debug: false,
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: LOAD_PATH,
      projectId: I18N_PROJECT_ID,
      apiKey: I18N_API_KEY,
      referenceLanguage: I18N_LNG,
    },
    saveMissing: IS_DEV_MODE,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;

import initializationService from 'src/service/InitializationService';
import tenantTagService from 'src/service/TenantTagService';
import publicFeatureService from 'src/service/PublicFeatureService';
import privateFeatureService from 'src/service/PrivateFeatureService';
import accessService from 'src/service/AccessService';
import productFeatureService from 'src/service/ProductFeatureService';
import schemaFeatureService from 'src/service/SchemaFeatureService';
import chatService from 'src/service/ChatService';

initializationService.public.register(tenantTagService);
initializationService.public.register(publicFeatureService);

initializationService.private.register(accessService);
initializationService.private.register(productFeatureService);
initializationService.private.register(schemaFeatureService);
initializationService.private.register(chatService);
initializationService.private.register(privateFeatureService);

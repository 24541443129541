import { ledger, claim, ums } from 'src/api/endpoint';
import tenantTagService from 'src/service/TenantTagService';

const getLedgerAccessActions = async () => {
  try {
    const { data } = await ledger.get('/access-management/actions');

    return data;
  } catch (error) {
    console.error(error);

    return [];
  }
};

const getClaimsAccessActions = async () => {
  try {
    const { data } = await claim.get('/access-management/actions');

    return data;
  } catch (error) {
    console.error(error);

    return [];
  }
};

const getUmsAccessActions = async () => {
  const { tenantTag } = tenantTagService;

  try {
    const data = await ums.get(`/${tenantTag}/access-management/actions`);

    return data;
  } catch (error) {
    console.error(error);

    return [];
  }
};

export default async () => {
  const actions = await Promise.all([
    getClaimsAccessActions(),
    getLedgerAccessActions(),
    getUmsAccessActions(),
  ]);

  return actions.flat();
};

import { lazy } from 'react';

export default [
  {
    path: '/public/claim/product',
    component: lazy(() => import('src/containersPublic/ClaimProduct')),
  },
  {
    path: '/public/claim/document/:name/:uuid',
    component: lazy(() =>
      import('src/containersPublic/ClaimDocuments/ClaimDocumentsPage')
    ),
  },
  {
    path: '/public/claim/:name/:type',
    component: lazy(() => import('src/containersPublic/ClaimNew')),
  },
  {
    path: '/public/thank-you',
    component: lazy(() => import('src/containersPublic/ThankYouPage')),
  },
  {
    path: '/cognito-auth/:userSub',
    component: lazy(() => import('src/containersPublic/CognitoAuth')),
  },
  {
    path: '/iprovider-auth/:identityProvider',
    component: lazy(() => import('src/containersPublic/CognitoAuth')),
  },
  {
    path: '/callback',
    component: lazy(() =>
      import('src/containersPublic/CognitoAuth/Callback.jsx')
    ),
  },
  {
    path: '/e-offer/',
    component: lazy(() => import('src/containersPublic/EOfferPage')),
  },
  {
    path: '/e-proposal/',
    component: lazy(() => import('src/containersPublic/EProposalPage')),
  },
];

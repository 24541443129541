import { combineReducers } from 'redux';

import auth from './auth';
import report from './report';
import brokers from './brokers';
import highRisks from './high-risks';
import endorsement from './endorsement';
import makeModel from './make-model';
import policyImport from './policy-import';

export default combineReducers({
  auth,
  endorsement,
  report,
  brokers,
  highRisks,
  makeModel,
  policyImport,
});

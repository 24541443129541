const LEAP_DAY = 29;
const LEAP_MONTH_INDEX = 1;
const YEAR_UNITS = ['year', 'years'];
const MONTH_UNITS = ['month', 'months'];
const YEAR_IN_MONTHS = 12;
const LEAP_YEAR_DAYS = 366;

export default (_, dayJsClass) => {
  const originalAdd = dayJsClass.prototype.add;

  dayJsClass.prototype.add = function(amount, unit) {
    if (this.date() === LEAP_DAY && this.month() === LEAP_MONTH_INDEX) {
      if (YEAR_UNITS.includes(unit))
        return originalAdd.call(this, LEAP_YEAR_DAYS * amount, 'day');
      if (MONTH_UNITS.includes(unit) && amount % YEAR_IN_MONTHS === 0)
        return originalAdd.call(
          this,
          (amount / YEAR_IN_MONTHS) * LEAP_YEAR_DAYS,
          'day'
        );
    }

    return originalAdd.call(this, amount, unit);
  };
};

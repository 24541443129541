const ID_TOKEN_NAME = '_token-id';
const ACCESS_TOKEN_NAME = '_token';
const REFRESH_TOKEN_NAME = '_token-refresh';

export class AwsService {
  get idToken() {
    return localStorage.getItem(ID_TOKEN_NAME);
  }

  get accessToken() {
    return localStorage.getItem(ACCESS_TOKEN_NAME);
  }

  get refreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_NAME);
  }

  get isSessionActive() {
    return !!this.accessToken && !!this.refreshToken && !!this.idToken;
  }

  setTokens({ accessToken, refreshToken, idToken }) {
    idToken && localStorage.setItem(ID_TOKEN_NAME, idToken);
    accessToken && localStorage.setItem(ACCESS_TOKEN_NAME, accessToken);
    refreshToken && localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);
  }

  dispose() {
    localStorage.removeItem(ID_TOKEN_NAME);
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem(REFRESH_TOKEN_NAME);
  }
}

export default new AwsService();

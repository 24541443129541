import buildFilterProps from 'src/helpers/buildFilterProps';

import * as EndorsementApi from '../endorsement';

export async function getEndorsementsList({ page, perPage, filters, search }) {
  const filterProps = buildFilterProps(filters);

  try {
    return await EndorsementApi.getEndorsementsList(
      page,
      perPage,
      search,
      filterProps
    );
  } catch (error) {
    throw {
      values: error.response.data,
    };
  }
}

export async function addEndorsement(data) {
  try {
    return await EndorsementApi.addEndorsement(data);
  } catch (error) {
    throw {
      values: error.response.data,
    };
  }
}

export async function updateEndorsement(id, data) {
  try {
    return await EndorsementApi.updateEndorsement(id, data);
  } catch (error) {
    throw {
      values: error.response.data,
    };
  }
}

export async function getEndorsement(id) {
  try {
    return await EndorsementApi.getEndorsement(id);
  } catch (error) {
    throw {
      values: error.response.data,
    };
  }
}

import * as HighRisksApi from 'src/api/interfaces/highRisks';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants';

/** initial state */
const initialState = {
  list: null,
  page: 1,
  rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  totalCount: 0,
};

/** selectors */
export const select = state => state.highRisks.list;
export const selectHighRisksPaginationData = state => ({
  page: state.highRisks.page,
  rowsPerPage: state.highRisks.rowsPerPage,
  totalCount: state.highRisks.totalCount,
});

/** action types */
const prefix = 'high-risks';
const ActionTypes = {
  FETCH_LIST: `${prefix}/FETCH_LIST`,
  FETCH_LIST_SUCCESS: `${prefix}/FETCH_LIST_SUCCESS`,
  FETCH_LIST_FAIL: `${prefix}/FETCH_LIST_FAIL`,
};

/** actions */
export function fetch(
  page = 1,
  perPage = DEFAULT_ROWS_PER_PAGE,
  searchterm = null
) {
  return async dispatch => {
    try {
      dispatch({ type: ActionTypes.FETCH_LIST });
      const data = await HighRisksApi.fetch(page, perPage, searchterm);

      dispatch({ type: ActionTypes.FETCH_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: ActionTypes.FETCH_LIST_FAIL,
      });
    }
  };
}

/** default reducer */
export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.FETCH_LIST:
      return {
        ...state,
        list: null,
      };
    case ActionTypes.FETCH_LIST_SUCCESS:
      return {
        list: payload.data,
        page: payload.current_page,
        rowsPerPage: payload.per_page,
        totalCount: payload.total,
      };
    case ActionTypes.FETCH_LIST_FAIL:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

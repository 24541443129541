import { ledger } from 'src/api/endpoint';

export const PAGE_LIMIT = 1000;

export function getEndorsementsUrl() {
  return '/sales/endorsements';
}

export function getEndorsementsList(page, limit, search, filters) {
  const endorsementsUrl = getEndorsementsUrl();

  return ledger.get(endorsementsUrl, {
    page,
    limit,
    search,
    ...filters,
  });
}

export function makeSearchEndorsement(limit = PAGE_LIMIT) {
  const endorsementsUrl = getEndorsementsUrl();
  const request = ledger.singleton('GET');

  return (search, filters) =>
    request(endorsementsUrl, { search, limit, ...filters });
}

export function getEndorsementGroups(productName) {
  const endorsementsUrl = getEndorsementsUrl();

  return ledger.get(`${endorsementsUrl}/groups/${productName}`);
}

export function addEndorsement(data) {
  const endorsementsUrl = getEndorsementsUrl();

  return ledger.post(endorsementsUrl, data);
}

export function updateEndorsement(id, data) {
  const endorsementsUrl = getEndorsementsUrl();

  return ledger.put(`${endorsementsUrl}/${id}`, data);
}

export function getEndorsement(id) {
  const endorsementsUrl = getEndorsementsUrl();

  return ledger.get(`${endorsementsUrl}/${id}`);
}

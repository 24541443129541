import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';

import { PasswordIconControl } from './index';

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(3),
  },
}));

const PasswordField = ({ showEye = false, isMasked = true, ...rest }) => {
  const classes = useStyles();

  const [isMaskedFlag, setMaskedFlag] = React.useState(isMasked);
  const passwordFieldType = isMaskedFlag ? 'password' : 'text';

  React.useEffect(() => setMaskedFlag(isMasked), [isMasked]);

  return (
    <TextField
      {...rest}
      className={classes.margin}
      type={passwordFieldType}
      variant="outlined"
      fullWidth
      InputProps={{
        endAdornment: showEye && (
          <PasswordIconControl value={isMaskedFlag} onChange={setMaskedFlag} />
        ),
      }}
    />
  );
};

export default PasswordField;

import * as BdxImportApi from '../bdxImport';

export async function getProducts() {
  try {
    return BdxImportApi.getProducts('/policies/bdx-import/products');
  } catch (error) {
    throw error.response.data;
  }
}

export async function getLog(page, limit) {
  try {
    return BdxImportApi.getLog(page, limit);
  } catch (error) {
    throw error.response.data;
  }
}

export async function bdxImport(
  schema,
  importFile,
  { broker, startedAt, brokerFromFile }
) {
  const formData = new FormData();

  formData.append('bdx_file', importFile, importFile.name);
  formData.append('started_at', startedAt.toISOString());
  formData.append('broker_from_file', Number(brokerFromFile));
  !brokerFromFile && formData.append('broker', broker);

  try {
    return await BdxImportApi.bdxImport(schema, formData, {
      waitInterval: 10000,
    });
  } catch (error) {
    throw error.response.data;
  }
}

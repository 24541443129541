import React, { useEffect, useMemo } from 'react';
import lodash from 'lodash';
import { useLocation, matchPath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';

import NavItem from 'src/components/NavItem';
import MiniDrawer, { ArrowIcon } from 'src/components/MiniDrawer';
import UserBadge, { UserActions } from 'src/components/UserBadge';
import { selectIsLoggedIn } from 'src/redux/modules/auth';
import accessService from 'src/service/AccessService';
import { LinkLogo } from 'src/components/LoginLogo';
import { HOME_PAGE } from 'src/constants';
import AccessActions from 'src/accessActions';

import MenuConfig from './MenuConfig';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: 20,
    },
  },
  drawerRoot: {
    zIndex: 3,
    position: 'relative',
    display: 'flex',
  },
  mobileDrawer: {
    width: 256,
    backgroundColor: theme.color.base.brand.regular,
  },
  desktopDrawer: {
    '& $navigation': {
      overflowX: 'hidden',
      fontSize: 15,
    },
    zIndex: 0,
  },
  arrowIcon: {
    position: 'absolute',
    right: -12,
    top: 38,
  },
  logoContainer: props => ({
    maxWidth: props.openDesktop ? '70%' : '100%',
    padding: '20px 8px',
    height: '30px',
    minHeight: '30px',
    marginBottom: 15,
    boxSizing: 'content-box',
    display: 'flex',
    alignItems: 'center',
  }),
  navigation: {
    overflow: 'auto',
    flexGrow: 1,
    scrollbarWidth: 'none',
    fontFamily: 'Inter',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    marginTop: 60,
    '&>ul>li:first-child': {
      marginBottom: props =>
        props.createQuotePermission ? theme.spacing(4) : 0,
    },
  },
  bottomLogoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 24,
  },
  bottomLogo: {
    height: 26,
    opacity: 0.7,
  },
  userBadge: {
    padding: 20,
    margin: -20,
    background: theme.color.base.brand.addition,
  },
}));

function renderNavItems({ items, subheader, key, ...props }) {
  return (
    <List key={key}>
      {subheader && <ListSubheader disableSticky>{subheader}</ListSubheader>}
      {items?.reduce(
        (accumulator, item) =>
          reduceChildRoutes({ accumulator, item, ...props }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  accumulator,
  pathname,
  item,
  depth = 0,
  ...props
}) {
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    accumulator.push(
      <NavItem
        href={item.href}
        depth={depth}
        icon={item.icon}
        key={`nav-item-${item.title}-${depth}`}
        label={item.label}
        open={Boolean(open)}
        title={item.title}
        isActive={item.isActive}
        {...props}
      >
        {renderNavItems({
          key: `nav-items-${item.title}-${depth}`,
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else
    accumulator.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        component={item.component}
        key={`${item.href}-${depth}`}
        label={item.label}
        title={item.title}
        isActive={item.isActive}
        {...props}
      />
    );

  return accumulator;
}

function NavBar({
  openMobile,
  onMobileClose,
  openDesktop,
  onDesktopToggle,
  ...props
}) {
  const theme = useTheme();
  const { logoSrc, collapsedLogoSrc } = theme.resources.logo.secondary;
  const location = useLocation();
  const { accessActions } = accessService;
  const createQuotePermission = accessActions.includes(
    AccessActions.QuoteCreate
  );

  const classes = useStyles({ openDesktop, createQuotePermission });

  const isLoggedIn = useSelector(selectIsLoggedIn);

  const isResourceTypePresent = resourceTypes =>
    resourceTypes.some(resourceType => accessActions.includes(resourceType));

  const protectNavItem = item => {
    const { items, resourceTypes } = item;

    if (!items)
      return !resourceTypes || isResourceTypePresent(resourceTypes)
        ? item
        : null;

    const protectedNavItems = items.map(protectNavItem).filter(Boolean);

    if (resourceTypes && isResourceTypePresent(resourceTypes))
      return {
        ...item,
        items: lodash.isEmpty(protectedNavItems) ? null : protectedNavItems,
      };

    if (!protectedNavItems.length) return null;

    return {
      ...item,
      items: protectedNavItems,
    };
  };

  const protectedNavItem = protectNavItem(MenuConfig);

  useEffect(() => {
    if (openMobile && onMobileClose) onMobileClose();
  }, [location.pathname]);

  const Logo = useMemo(() => {
    if (openDesktop)
      return !!logoSrc && <LinkLogo src={logoSrc} href={HOME_PAGE} />;
    else {
      if (collapsedLogoSrc)
        return <LinkLogo src={collapsedLogoSrc} href={HOME_PAGE} />;

      if (logoSrc) return <LinkLogo src={logoSrc} href={HOME_PAGE} />;

      return null;
    }
  }, [openDesktop, logoSrc, collapsedLogoSrc]);

  const NavBarContent = (
    <div {...props} className={classes.root}>
      <div className={classes.logoContainer}>{Logo}</div>
      <nav className={classes.navigation}>
        {renderNavItems({
          items: protectedNavItem.items,
          key: 'root-nav-items',
          expanded: openDesktop,
          onDesktopToggle: onDesktopToggle,
        })}
      </nav>
      {isLoggedIn && (
        <UserBadge
          action={UserActions}
          className={classes.userBadge}
          expanded={openDesktop}
        />
      )}
    </div>
  );

  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer,
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {NavBarContent}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <div className={classes.drawerRoot}>
          <MiniDrawer
            expanded={openDesktop}
            onToggle={onDesktopToggle}
            anchor="left"
            variant="persistent"
            className={classes.desktopDrawer}
          >
            {NavBarContent}
          </MiniDrawer>
          <ArrowIcon
            expanded={openDesktop}
            className={classes.arrowIcon}
            onClick={onDesktopToggle}
          />
        </div>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  onDesktopToggle: PropTypes.func,
  openMobile: PropTypes.bool,
  openDesktop: PropTypes.bool,
};

export default NavBar;

import * as HighRisksApi from '../highRisks';

export async function fetch(page, limit, searchterm) {
  try {
    return await HighRisksApi.fetch(page, limit, searchterm);
  } catch (error) {
    throw error.response.data;
  }
}

export async function getItemData(id) {
  try {
    return await HighRisksApi.getItemData(id);
  } catch (error) {
    throw error.response.data;
  }
}

export async function edit(id, data) {
  try {
    return await HighRisksApi.edit(id, data);
  } catch (error) {
    throw error.response.data;
  }
}

export async function insert(data) {
  try {
    return await HighRisksApi.insert(data);
  } catch (error) {
    throw error.response.data;
  }
}

export async function fileUpload(data) {
  const formData = new FormData();

  formData.append('file', data);

  try {
    return await HighRisksApi.fileUpload(formData);
  } catch (error) {
    throw error.response.data;
  }
}

export async function exportFile() {
  try {
    return await HighRisksApi.exportFile();
  } catch (error) {
    throw error.response.data;
  }
}

export async function getLog() {
  try {
    return await HighRisksApi.getLog();
  } catch (error) {
    throw error.response.data;
  }
}

import React from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import notify from 'src/service/NotifyService';

export default () => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(
    () =>
      notify.subscribe(
        ({ message, variant, options: { afterShow, ...options } }) => {
          const action = key => options.action?.(() => closeSnackbar(key));

          const key = enqueueSnackbar(t(message), {
            ...options,
            variant,
            action,
          });

          afterShow?.(() => closeSnackbar(key));
        }
      ),
    []
  );

  return null;
};

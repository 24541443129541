import getAccessActions from 'src/api/accessActions';
import { Initializer } from 'src/lib/initializeManager';

class AccessService extends Initializer {
  #accessActions = null;

  async initialize() {
    this.#accessActions = await getAccessActions();
  }

  get accessActions() {
    return this.#accessActions;
  }

  dispose() {
    this.#accessActions = null;
  }
}

export default new AccessService();

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { logout, selectIsLoggedIn } from 'src/redux/modules/auth';
import awsService from 'src/service/AwsService';
import { isPrivatePath } from 'src/routes';

import useTracker from './useTracker';
import useNote from './useNote';
import { NOTIFICATION_DELAY, LOGOUT_DELAY } from './constants';
import { getDuration, isSessionTimeout } from './utils';

export default () => {
  const {
    isActive,
    subscriber,
    stopTracking,
    startTracking,
    clearTracking,
  } = useTracker();
  const note = useNote();
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const isPrivateRoute = () => isPrivatePath(history.location.pathname);

  const disposeTracking = () => {
    note.hideNote();
    stopTracking();
    clearTracking();
  };

  const logoutFromSystem = () => {
    note.hideNote();
    dispatch(logout());

    if (isPrivateRoute()) history.push('/login?redirect');
  };

  // Handle user inactivity duration
  React.useEffect(
    () =>
      subscriber.subscribe(duration => {
        if (!isActive) return;

        if (awsService.isSessionActive)
          if (duration < NOTIFICATION_DELAY) note.hideNote();
          else if (
            duration > NOTIFICATION_DELAY &&
            duration < LOGOUT_DELAY &&
            isPrivateRoute()
          )
            note.showNote();

        if (isSessionTimeout()) {
          disposeTracking();

          if (awsService.isSessionActive) logoutFromSystem();
        }
      }),
    []
  );

  // Restore state after tab inactivity
  React.useEffect(() => {
    if (!isActive) return;

    // Session exist on client side, check duration
    if (awsService.isSessionActive) {
      const duration = getDuration();

      if (isSessionTimeout()) logoutFromSystem();
      else if (duration > NOTIFICATION_DELAY && isPrivateRoute())
        note.showNote();
      else note.hideNote();
    }

    // Session does not exist on client side, logout
    if (!awsService.isSessionActive) logoutFromSystem();
  }, [isActive]);

  // Mute inactive tab or restore tracking on active tab
  React.useEffect(() => {
    if (isActive && awsService.isSessionActive) startTracking();
    else stopTracking();

    if (!isLoggedIn) disposeTracking();
  }, [isActive, isLoggedIn]);

  return null;
};

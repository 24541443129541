import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectIsLoggedIn } from 'src/redux/modules/auth';
import tenantTagService from 'src/service/TenantTagService';

const NO_REDIRECT_PATH = '/callback';

export default () => {
  const location = useLocation();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  React.useEffect(() => {
    const { redirectUrl } = tenantTagService;
    const { pathname } = location;

    if (redirectUrl && !isLoggedIn && pathname !== NO_REDIRECT_PATH)
      window.location.href = redirectUrl;
  }, []);

  return null;
};

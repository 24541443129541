import mixin from 'src/theme/mixin';

export default {
  root: {
    boxShadow: 'none',
    fontWeight: 500,
    '&$disabled': {
      color: 'inherit',
      boxShadow: 'none',
      opacity: 0.2,
    },
    '&$focusVisible': {
      color: 'inherit',
      boxShadow: 'none',
    },
  },
  label: {
    ...mixin.upperCaseFirstLetter,
    lineHeight: '24px',
    paddingEnd: 0,
    fontStretch: 'condensed',
    animation: 'triggerRender 1ms',
  },
  contained: {
    boxShadow: 'none',
    '&:hover, &:focus': {
      boxShadow: '0px 0px 16px rgba(0, 0, 15, 0.05)',
    },
  },
  outlined: {
    boxShadow: 'none',
    '&:hover, &:focus': {
      backgroundColor: 'rgba(0, 0, 15, 0.05)',
    },
  },
};

import apiErrorService from 'src/service/ApiErrorService';

export default Target =>
  class extends Target {
    interceptors() {
      this._commonTransport.interceptors.response.use(null, error =>
        this._errorInterceptor(error)
      );

      super.interceptors();
    }

    _errorInterceptor(error) {
      const { preventErrorHandler } = this._requestConfig;

      if (
        !this.isCanceled(error) &&
        !preventErrorHandler &&
        !error?.config?.preventErrorHandler
      )
        apiErrorService.push(error);

      return Promise.reject(error);
    }
  };

import { TRACK_STORAGE_NAME, LOGOUT_DELAY } from './constants';

export const initTrackTime = () => {
  const currentTime = +Date.now();
  const trackTime = localStorage.getItem(TRACK_STORAGE_NAME);

  if (!trackTime) localStorage.setItem(TRACK_STORAGE_NAME, currentTime);
};

export const getDuration = () => {
  const currentTime = +Date.now();
  const trackTime = localStorage.getItem(TRACK_STORAGE_NAME);

  if (!trackTime) return null;

  return currentTime - trackTime;
};

export const trackTime = () => {
  const currentTime = +Date.now();
  const trackTime = localStorage.getItem(TRACK_STORAGE_NAME);

  if (!trackTime || trackTime < currentTime)
    localStorage.setItem(TRACK_STORAGE_NAME, currentTime);
};

export const checkTime = subscriber => {
  const duration = getDuration();

  if (duration) subscriber.publish(duration);
};

export const isSessionTimeout = () => {
  const duration = getDuration();

  return duration > LOGOUT_DELAY;
};

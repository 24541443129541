import React from 'react';

import { getProductSchemas } from 'src/api/policy';

export default () => {
  const [schemas, setSchemas] = React.useState([]);
  const [isLoading, setLoadingState] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      const schemas = await getProductSchemas();

      setSchemas(schemas);
      setLoadingState(false);
    })();
  }, []);

  return [isLoading, schemas];
};

import { colors } from '@material-ui/core';
import { lighten, darken } from '@material-ui/core/styles/colorManipulator';

const white = '#FFFFFF';
const primary = localStorage.getItem('color') || '#00000F';

export default {
  primary: {
    contrastText: white,
    main: primary,
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue.A700,
    light: colors.blue.A400,
  },
  text: {
    primary,
    secondary: colors.blueGrey[500],
    link: colors.blue[600],
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: '#E9F0F3',
    paper: white,
  },
  divider: lighten(primary, 0.95),
  label: darken(colors.grey[500], 0.05),
};

import { lighten } from '@material-ui/core/styles';

import palette from '../palette';

export default {
  root: {},
  notchedOutline: {
    borderColor: lighten(palette.primary.main, 0.88),
  },
};
